
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BillwizerLogoLight, GoogleSignIn, AppleSignIn } from "../_components/Imagepath";
import { useForm, Controller } from "react-hook-form";

import AuthService from "../services/auth.service";

import { useHistory } from "react-router-dom";

import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import AppleLogin from 'react-apple-login';


const Login = () => {
  const { handleSubmit, control, setError, formState: { errors } } = useForm();
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // success or error
  const [appToken, setAppToken] = useState('');
  const [loginCode, setLoginCode] = useState('');
  const [email, setEmail] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [timer, setTimer] = useState(0); // Timer state
  const [canResend, setCanResend] = useState(false); // Resend button state
  const history = useHistory();

  useEffect(() => {
    let interval;
    if (isCodeSent && timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setCanResend(true); // Enable resend after 60 seconds
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [isCodeSent, timer]);

  const handleSendLoginCode = async (data) => {
    console.log('handleSendLoginCode');
    const { email } = data;
    setEmail(email); // Save email to state

    try {
      const response = await axios.post('https://poweredby.in/invoice_app/admin/webapi/sendlogincode', { email });

      // Check for errorMessage in the response
      if (response.data.errorMessage === "Inactive User ID.") {
        setMessage('Email not registered. Please sign up.');
        setMessageType('error');
        setIsCodeSent(false); // Do not show the login code input
        setCanResend(false); // Disable resend button
      } else {
        setAppToken(response.data.data.apptoken);
        setMessage(`Login code sent to ${email}.`);
        setMessageType('success');
        setIsCodeSent(true); // Show the login code input
        setTimer(90); // Set timer for 60 seconds
        setCanResend(false); // Disable resend button
      }
    } catch (error) {
      // Handle different types of errors
      if (error.response && error.response.data && error.response.data.errorMessage === "Inactive User ID.") {
        setMessage('Email not registered. Please sign up.');
        setMessageType('error');
      } else {
        setMessage('Error sending login code.');
        setMessageType('error');
      }
      setIsCodeSent(false); // Do not show the login code input
      setCanResend(false); // Disable resend button
    }
  };

  const handleResendCode = () => {
    handleSendLoginCode({ email }); // Resend the login code
  };

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Ensure loginCode is a valid 6-digit number
    const isValidCode = /^\d{6}$/.test(loginCode.trim());
    if (!isValidCode) {
      setMessage('Please enter a valid 6-digit login code.');
      setMessageType('error');
      return;
    }
    
    // Ensure appToken is available
    if (!appToken) {
      setMessage('Invalid or missing application token.');
      setMessageType('error');
      return;
    }

    try {
      console.log('Attempting to log in with:', { email, appToken, loginCode });
      const response = await axios.post('https://poweredby.in/invoice_app/admin/webapi/customerlogincodebasefinal', { email, appToken, loginCode });
      console.log('API response:', response.data);
      console.log(response.data.errorMessage);
      // Check if the response indicates a successful login
      if (response.data && response.data.data && response.data.data.apptoken) {
        setMessage('Login successful!');
        setMessageType('success');
        console.log('Login successful, response data:', response.data.data);
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem("token", response.data.data.apptoken);
        history.push('/index');
        window.location.reload();
      } else {
        setMessage('Invalid login code.');
        setMessageType('error');
        console.log('Invalid login code or response does not contain token:', response.data);
      }
    } catch (error) {
      // Check for specific error response from backend
      if (error.response && error.response.data) {
        const errorResponse = error.response.data;
        if (errorResponse.errorMessage === "Invalid login code.") {
          setMessage('Invalid login code.');
        } else {
          setMessage(`Error: ${errorResponse.errorMessage || 'An unexpected error occurred.'}`);
        }
      } else {
        setMessage('An unexpected error occurred.');
      }
      setMessageType('error');
      console.error('Login failed:', error);
    }
  };

  // const Googlelogin = useGoogleLogin({
  //   onSuccess: tokenResponse => console.log(tokenResponse),
  // });

  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);

  const Googlelogin = useGoogleLogin({
    onSuccess: codeResponse => {
      if(codeResponse){
      setUser(codeResponse);
      console.log(codeResponse);
      }
    }
  });


  useEffect(
    () => {
      if (user) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            console.log(res);
            setProfile(res.data);
          })
          .catch((err) => console.log(err));
      }
    },
    [user]
  );



  useEffect(() => {
   
    const loginAndRedirect = async () => {
      if (profile.email) {
        console.log(profile.email);
        try {

          let res = await AuthService.APILogin(profile.email, profile.id); // Assuming AuthService.login expects email and password as parameters

          console.log("data rrrrr");
          console.log(res.data.name);
          history.push("/index");
          console.log(res.data.name);
          window.location.reload(); // Refresh the page (optional)
        } catch (error) {
          console.error('Login failed 222:', error);

          const errorMessage = error.message || "An error occurred during login";

          setError("apierror", { message: errorMessage });
          // Handle login failure (e.g., display error message)
          // Example: setMessage(error.message);
        }
      }
    };

    loginAndRedirect();
  }, [profile]);

  // eslint-disable-next-line no-unused-vars
  const [appleresponse, setAppleResponse] = useState(null);

  const appleResponse = response => {

    
    if (response.authorization) {
      setAppleResponse(response.authorization);
    }
    


  };


  useEffect(() => {
   
    const loginCheckResponse = async () => {
      if (appleresponse) {
        console.log(appleresponse);
        try {

          let res = await AuthService.APIAppleRespnseCheck(appleresponse.code, appleresponse.id_token); // Assuming AuthService.login expects email and password as parameters

          setProfile({email: res.email, id:""});
        
        } catch (error) {
          console.error('Login failed 222:', error);

          const errorMessage = error.message || "An error occurred during login";

          setError("apierror", { message: errorMessage });
          // Handle login failure (e.g., display error message)
          // Example: setMessage(error.message);
        }
      }
    };

    loginCheckResponse();
  }, [appleresponse]);





  return (
    <div className="main-wrapper login-body">
      <div className="login-wrapper">
        <div className="container">
          <img
            className="img-fluid logo-dark mb-2"
            src={BillwizerLogoLight}
            alt="Logo"
          />
          <div className="loginbox boardingwizard">
            <div className="login-right">
              <div className="login-right-wrap">
                <h1>Sign In</h1>
                {!isCodeSent ? (
                  <form onSubmit={handleSubmit(handleSendLoginCode)}>
                    <div className="input-block mb-3 input_text">
                      <label className="form-control-label">
                        Email Address
                      </label>
                      <Controller
                        name="email"
                        control={control}
                        defaultValue="" 
                        render={({ field: { value, onChange } }) => (
                          <input
                            className={`form-control ${errors?.email ? "error-input" : ""}`}
                            type="email"
                            value={value}
                            onChange={onChange}
                            autoComplete="false"
                          />
                        )}
                      />
                      <small className="error">{errors?.email?.message}</small>
                      {message && (
                        <small className={`message ${messageType === 'success' ? 'message-success' : 'message-error'}`}>
                          {message}
                        </small>
                      )}
                    </div>
                    <button
                      className="btn btn-lg btn-block w-100 btn-primary w-100"
                      type="submit"
                    >
                      Send Code
                    </button>
                  </form>
                ) : (
                  <form onSubmit={handleLogin}>
                    <div className="input-block mb-3 input_text">
                      <label className="form-control-label">
                        Login Code
                      </label>
                      <input
                        className={`form-control ${errors?.loginCode ? "error-input" : ""}`}
                        type="text"
                        value={loginCode}
                        onChange={(e) => setLoginCode(e.target.value)}
                        autoComplete="false"
                      />
                      <small className="error">{errors?.loginCode?.message}</small>
                      {message && (
                        <small className={`message ${messageType === 'success' ? 'message-success' : 'message-error'}`}>
                          {message}
                        </small>
                      )}
                      {isCodeSent && (
                        <div className="resend-container">
                          <p className="resend-timer">
                            {canResend ? (
                              <button
                                className="btn btn-link"
                                onClick={handleResendCode}
                              >
                                Resend Code
                              </button>
                            ) : (
                              `Resend code in ${Math.floor(timer / 60)}:${timer % 60 < 10 ? '0' : ''}${timer % 60}`
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                    <button
                      className="btn btn-lg btn-block w-100 btn-primary w-100"
                      type="submit">
                      Login
                    </button>
                  </form>
                )}
                <div className="login-or">
                  <span className="or-line" />
                  <span className="span-or">or</span>
                </div>
                {/* Social Login */}
                <div className="social-login">
                  <div className="row">
                    <div className="col-12 col-sm col-md-6 col-lg-6">
                      <button className="custom-google-signin-button" onClick={() => Googlelogin()}>
                        <img src={GoogleSignIn} alt="Google logo" className="google-logo" />
                      </button>
                      </div>
                      <div className="col-12 col-sm col-md-6 col-lg-6">
                      <AppleLogin
                        clientId={"com.billwizer.webapp"}
                        scope={"email name"}
                        redirectURI={"https://app.billwizer.com/login"}
                        responseType={"code"}
                        responseMode={"query"}
                        usePopup={true}
                        callback={appleResponse}
                        render={({ onClick }) => (
                          <button onClick={onClick} className="custom-google-signin-button">
                            <img
                              className="google-logo"
                              src={AppleSignIn}
                              alt="Sign in with Apple"
                            />
                          </button>
                        )}
                      />
                      </div>
                      <small className="error">{errors?.apierror?.message}</small>
                    </div>
                </div>
                {/* Social Login */}
                <div className="text-center dont-have">
                  Don&apos;t have an account yet? <Link to="/register">Sign Up</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;