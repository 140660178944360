// services/invoice.service.js
import axios from 'axios';
import AuthService from "../services/auth.service";

import config from './config';

import {formatDateISONEW} from "../services/Helper";

const API_URL = config.API_URL;


  // eslint-disable-next-line no-unused-vars
const currentUser = AuthService.getCurrentUser();

const getInvoiceSummaryReport = async (listType, filteroption, is_export = "", type = "") => {
  try {
    const currentUser = await AuthService.getCurrentUser();
    if (!currentUser || !currentUser.apptoken) {
      AuthService.setCurrentUser(currentUser);
      throw new Error("User not authenticated or missing apptoken.");
    }

    return axios
      .post(API_URL + "customerReportSales", {
        apptoken: currentUser.apptoken, // Ensure the payload is correct
        linkModeType: listType,
        reporttype: "salessummary",
        filterText: filteroption.searchterm,
        fdate: formatDateISONEW(filteroption.fdate),
        tdate: formatDateISONEW(filteroption.tdate),
        is_export: is_export,
        type: type,
     
      })
      .then((response) => {
        if (response.data.errorMessage) {
          throw new Error(response.data.errorMessage);
        }

        if (response.data.data && response.data.data.username) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        } else {
          console.warn("No username found in response data.");
        }

        return response.data;
      });
  } catch (error) {
    console.error("Error fetching invoices:", error);
    throw error;
  }
};


const getInvoiceSummaryDetailsReport = async (listType, filteroption) => {
  try {
    const currentUser = await AuthService.getCurrentUser();
    if (!currentUser || !currentUser.apptoken) {
      AuthService.setCurrentUser(currentUser);
      throw new Error("User not authenticated or missing apptoken.");
    }

    return axios
      .post(API_URL + "customerReportSales", {
        apptoken: currentUser.apptoken, // Ensure the payload is correct
        linkModeType: listType,
        reporttype: "salesdetails",
        filterText: filteroption.searchterm,
        fdate: formatDateISONEW(filteroption.fdate),
        tdate: formatDateISONEW(filteroption.tdate),
     
      })
      .then((response) => {
        if (response.data.errorMessage) {
          throw new Error(response.data.errorMessage);
        }

        if (response.data.data && response.data.data.username) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        } else {
          console.warn("No username found in response data.");
        }

        return response.data;
      });
  } catch (error) {
    console.error("Error fetching invoices:", error);
    throw error;
  }
};



const getBalancesReport = async (listType, filteroption) => {
  try {
    const currentUser = await AuthService.getCurrentUser();
    if (!currentUser || !currentUser.apptoken) {
      AuthService.setCurrentUser(currentUser);
      throw new Error("User not authenticated or missing apptoken.");
    }

    return axios
      .post(API_URL + "customerReportSales", {
        apptoken: currentUser.apptoken, // Ensure the payload is correct
        linkModeType: listType,
        reporttype: "CustomerBalances",
        filterText: filteroption.searchterm,
        fdate: formatDateISONEW(filteroption.fdate),
        tdate: formatDateISONEW(filteroption.tdate),
     
      })
      .then((response) => {
        if (response.data.errorMessage) {
          throw new Error(response.data.errorMessage);
        }

        if (response.data.data && response.data.data.username) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        } else {
          console.warn("No username found in response data.");
        }

        return response.data;
      });
  } catch (error) {
    console.error("Error fetching invoices:", error);
    throw error;
  }
};

const getPaymentsReceivedReport = async (listType, filteroption) => {
  try {
    const currentUser = await AuthService.getCurrentUser();
    if (!currentUser || !currentUser.apptoken) {
      AuthService.setCurrentUser(currentUser);
      throw new Error("User not authenticated or missing apptoken.");
    }

    return axios
      .post(API_URL + "customerReportSales", {
        apptoken: currentUser.apptoken, // Ensure the payload is correct
        linkModeType: "invoicelist",
        reporttype: "PaymentRecived",
        filterText: filteroption.searchterm,
        fdate: formatDateISONEW(filteroption.fdate),
        tdate: formatDateISONEW(filteroption.tdate),
     
      })
      .then((response) => {
        if (response.data.errorMessage) {
          throw new Error(response.data.errorMessage);
        }

        if (response.data.data && response.data.data.username) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        } else {
          console.warn("No username found in response data.");
        }

        return response.data;
      });
  } catch (error) {
    console.error("Error fetching invoices:", error);
    throw error;
  }
};



const getTwentySixASReport = async (listType, filteroption) => {
  try {
    const currentUser = await AuthService.getCurrentUser();
    if (!currentUser || !currentUser.apptoken) {
      AuthService.setCurrentUser(currentUser);
      throw new Error("User not authenticated or missing apptoken.");
    }

    return axios
      .post(API_URL + "customergetTdsType", {
        apptoken: currentUser.apptoken, // Ensure the payload is correct
        linkModeType: "invoicelist",
        reporttype: "tdsinvoice",
        filterText: filteroption.searchterm,
        fdate: formatDateISONEW(filteroption.fdate),
        tdate: formatDateISONEW(filteroption.tdate),
     
      })
      .then((response) => {
        if (response.data.errorMessage) {
          throw new Error(response.data.errorMessage);
        }

        if (response.data.data && response.data.data.username) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        } else {
          console.warn("No username found in response data.");
        }

        return response.data;
      });
  } catch (error) {
    console.error("Error fetching invoices:", error);
    throw error;
  }
};


const getClientLedegerReport = async (listType, filteroption) => {
  try {
    const currentUser = await AuthService.getCurrentUser();
    if (!currentUser || !currentUser.apptoken) {
      AuthService.setCurrentUser(currentUser);
      throw new Error("User not authenticated or missing apptoken.");
    }

    return axios
      .post(API_URL + "getCustomers", {
        apptoken: currentUser.apptoken, // Ensure the payload is correct
      
        filterText: filteroption.searchterm,
        fdate: formatDateISONEW(filteroption.fdate),
        tdate: formatDateISONEW(filteroption.tdate),
     
      })
      .then((response) => {
        if (response.data.errorMessage) {
          throw new Error(response.data.errorMessage);
        }

        if (response.data.data && response.data.data.username) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        } else {
          console.warn("No username found in response data.");
        }

        return response.data;
      });
  } catch (error) {
    console.error("Error fetching invoices:", error);
    throw error;
  }
};



const getTaxRegisterReport = async (listType, filteroption) => {
  try {
    const currentUser = await AuthService.getCurrentUser();
    if (!currentUser || !currentUser.apptoken) {
      AuthService.setCurrentUser(currentUser);
      throw new Error("User not authenticated or missing apptoken.");
    }

    return axios
      .post(API_URL + "customerReportSales", {
        apptoken: currentUser.apptoken, // Ensure the payload is correct
        linkModeType: "invoicelist",
        reporttype: "outputGst",
        is_export: false,
        client_id: filteroption.client_id,
        filterText: filteroption.searchterm,
        fdate: formatDateISONEW(filteroption.fdate),
        tdate: formatDateISONEW(filteroption.tdate),
     
      })
      .then((response) => {
        if (response.data.errorMessage) {
          throw new Error(response.data.errorMessage);
        }

        if (response.data.data && response.data.data.username) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        } else {
          console.warn("No username found in response data.");
        }

        return response.data;
      });
  } catch (error) {
    console.error("Error fetching invoices:", error);
    throw error;
  }
};



const getTaxRegisterReportDetail = async (listType, filteroption) => {
  try {
    const currentUser = await AuthService.getCurrentUser();
    if (!currentUser || !currentUser.apptoken) {
      AuthService.setCurrentUser(currentUser);
      throw new Error("User not authenticated or missing apptoken.");
    }

    return axios
      .post(API_URL + "customerReportSales", {
        apptoken: currentUser.apptoken, // Ensure the payload is correct
        linkModeType: "invoicelist",
        reporttype: "outputGstDetails",
        is_export: false,
        client_id: filteroption.client_id,
        filterText: filteroption.searchterm,
        fdate: formatDateISONEW(filteroption.fdate),
        tdate: formatDateISONEW(filteroption.tdate),
     
      })
      .then((response) => {
        if (response.data.errorMessage) {
          throw new Error(response.data.errorMessage);
        }

        if (response.data.data && response.data.data.username) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        } else {
          console.warn("No username found in response data.");
        }

        return response.data;
      });
  } catch (error) {
    console.error("Error fetching invoices:", error);
    throw error;
  }
};


const getTaxRegisterReportDownload = async (listType, filteroption) => {
  try {
    const currentUser = await AuthService.getCurrentUser();
    if (!currentUser || !currentUser.apptoken) {
      AuthService.setCurrentUser(currentUser);
      throw new Error("User not authenticated or missing apptoken.");
    }

    return axios
      .post(API_URL + "customergetTdsTypeExport", {
        apptoken: currentUser.apptoken, // Ensure the payload is correct
        linkModeType: "invoicelist",
        reporttype: "outputGst",
        is_export: "export",
        filterText: filteroption.searchterm,
        client_id: filteroption.client_id,
        fdate: formatDateISONEW(filteroption.fdate),
        tdate: formatDateISONEW(filteroption.tdate),
     
      })
      .then((response) => {
        if (response.data.errorMessage) {
          throw new Error(response.data.errorMessage);
        }

        if (response.data.data && response.data.data.username) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        } else {
          console.warn("No username found in response data.");
        }

        return response.data;
      });
  } catch (error) {
    console.error("Error fetching invoices:", error);
    throw error;
  }
};


const getTaxRegisterDetailReportDownload = async (listType, filteroption) => {
  try {
    const currentUser = await AuthService.getCurrentUser();
    if (!currentUser || !currentUser.apptoken) {
      AuthService.setCurrentUser(currentUser);
      throw new Error("User not authenticated or missing apptoken.");
    }

    return axios
      .post(API_URL + "customergetTdsTypeExport", {
        apptoken: currentUser.apptoken, // Ensure the payload is correct
        linkModeType: "invoicelist",
        reporttype: "outputGstDetails",
        is_export: "export",
        client_id: filteroption.client_id,
        filterText: filteroption.searchterm,
        fdate: formatDateISONEW(filteroption.fdate),
        tdate: formatDateISONEW(filteroption.tdate),
     
      })
      .then((response) => {
        if (response.data.errorMessage) {
          throw new Error(response.data.errorMessage);
        }

        if (response.data.data && response.data.data.username) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        } else {
          console.warn("No username found in response data.");
        }

        return response.data;
      });
  } catch (error) {
    console.error("Error fetching invoices:", error);
    throw error;
  }
};

const getExpiringServicesReport = async (listType, filteroption) => {
  try {
    const currentUser = await AuthService.getCurrentUser();
    if (!currentUser || !currentUser.apptoken) {
      AuthService.setCurrentUser(currentUser);
      throw new Error("User not authenticated or missing apptoken.");
    }

    return axios
      .post(API_URL + "customerExpireReport", {
        apptoken: currentUser.apptoken, // Ensure the payload is correct
        linkModeType: listType,
        reporttype: "salesexpire",
        filterText: filteroption.searchterm,
        fdate: formatDateISONEW(filteroption.fdate),
        tdate: formatDateISONEW(filteroption.tdate),
        selectedDuration: 100,
     
      })
      .then((response) => {
        if (response.data.errorMessage) {
          throw new Error(response.data.errorMessage);
        }

        if (response.data.data && response.data.data.username) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        } else {
          console.warn("No username found in response data.");
        }

        return response.data;
      });
  } catch (error) {
    console.error("Error fetching invoices:", error);
    throw error;
  }
};


const getReportDownloadCombine = async (listType, filteroption, is_export = "", type = "",reporttype = "") => {
  try {
    const currentUser = await AuthService.getCurrentUser();
    if (!currentUser || !currentUser.apptoken) {
      AuthService.setCurrentUser(currentUser);
      throw new Error("User not authenticated or missing apptoken.");
    }

    return axios
      .post(API_URL + "customergetTdsTypeExport", {
        apptoken: currentUser.apptoken, // Ensure the payload is correct
        linkModeType: "invoicelist",
        reporttype: reporttype,
        is_export: is_export,
        filterText: filteroption.searchterm,
        fdate: formatDateISONEW(filteroption.fdate),
        tdate: formatDateISONEW(filteroption.tdate),
        type:type,
     
      })
      .then((response) => {
        if (response.data.errorMessage) {
          throw new Error(response.data.errorMessage);
        }

        if (response.data.data && response.data.data.username) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        } else {
          console.warn("No username found in response data.");
        }

        return response.data;
      });
  } catch (error) {
    console.error("Error fetching invoices:", error);
    throw error;
  }
};



  // eslint-disable-next-line no-unused-vars
  const handleDownloadPdf = async (url) => {
    try {
      const response = await axios.get(url, {
        responseType: 'blob',
      });
  
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'downloaded.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the PDF', error);
    }
  };

    // eslint-disable-next-line no-unused-vars
    const getledgerData = async (listType, filteroption, selectedinvoice, client_id) => {
      console.log(selectedinvoice);
    
      try {
      
       
    
        let pdfgenurl = `${API_URL}getledgerData?&xp=1&apptoken=${currentUser.apptoken}&client_id=${client_id}&fdate=${formatDateISONEW(filteroption.fdate)}&tdate=${formatDateISONEW(filteroption.tdate)}`;

        console.log("getCurrentUser", pdfgenurl);
    
        handleDownloadPdf(pdfgenurl);
        // handleDownloadPdf("https://poweredby.in/invoice_app/admin/webapi/generatepdf?p=1&a=1&user_id=840");
      } catch (error) {
        console.error('Login failed:', error);
      }
    };




export default {
  getInvoiceSummaryReport,
  getInvoiceSummaryDetailsReport,
  getBalancesReport,
  getPaymentsReceivedReport,
  getTwentySixASReport,
  getClientLedegerReport,
  getTaxRegisterReport,
  getTaxRegisterReportDetail,
  getTaxRegisterReportDownload,
  getTaxRegisterDetailReportDownload,
  getExpiringServicesReport,
  getReportDownloadCombine,
  handleDownloadPdf,
  getledgerData
  
};
