import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ApexCharts from "apexcharts";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import Dasshboard from "../../services/dashboard.service";
import moment from "moment";

const Dashboard = () => {
  const history = useHistory();
  const [menu, setMenu] = useState(false);
  const [dashboarddata, setDashBoardData] = useState({});

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = async () => {
    try {
      const response = await Dasshboard.getDashboard();
      setDashBoardData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const invoices = dashboarddata?.recentinvoice?.data || [];
  const estimates = dashboarddata?.recentestimate?.data || [];

  useEffect(() => {
    if (dashboarddata?.totalsales !== undefined) {
      const salesOptions = {
        colors: ["#7638ff", "#22cc62"],
        chart: {
          type: "bar",
          fontFamily: "Poppins, sans-serif",
          height: 350,
          toolbar: { show: false },
        },
        series: [
          { name: "Total Sales", type: "column", data: dashboarddata?.graph.invoices },
          { name: "Receipts", type: "column", data:  dashboarddata?.graph.paymentrec },
        ],
        plotOptions: {
          bar: { horizontal: false, columnWidth: "60%", endingShape: "rounded" },
        },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        xaxis: {
          categories: dashboarddata?.graph.month,
        },
        yaxis: { title: { text: `${dashboarddata.symbol} (thousands)` } },
        fill: { opacity: 1 },
        tooltip: {
          y: {
            formatter: function (val) {
              return `${dashboarddata.symbol} ` + val + " thousands";
            },
          },
        },
        responsive: [
          {
            breakpoint: 380,
            options: {
              chart: { width: 300 },
              legend: { position: "bottom" },
            },
          },
        ],
      };

      const invoiceOptions = {
        colors: ["#7638ff", "#1ec1b0",  "#ff737b"],
        chart: {
          fontFamily: "Poppins, sans-serif",
          height: 250,
          type: "donut",
        },
        series: [dashboarddata.totalsales, dashboarddata.recipts , dashboarddata.pending > 0 ? dashboarddata.pending : 0],
        labels: ["Invoiced", "Receipts", "Pending"],
        legend: { show: false },
        responsive: [
          {
            breakpoint: 300,
            options: {
              chart: { width: 300 },
              legend: { position: "bottom" },
            },
          },
        ],
      };

      let salesColumn = document.getElementById("sales_chart");
      let salesChart = new ApexCharts(salesColumn, salesOptions);
      salesChart.render();

      let invoiceColumn = document.getElementById("invoice_chart");
      let invoiceChart = new ApexCharts(invoiceColumn, invoiceOptions);
      invoiceChart.render();
    }
  }, [dashboarddata]);

  const handleSubmenu = (type, listType, selectedinvoice) => {
    switch (listType) {
      case "invoicelist":
        switch (type) {
          case "edit":
            history.push(`/edit-invoice`, { selectedinvoice });
            break;
          default:
            break;
        }
        break;
      case "estimatelist":
        switch (type) {
          case "edit":
            history.push(`/edit-estimate`, { selectedinvoice });
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  const handleCardClick = () => {
    history.push(`/customers`);
   
  };
  const handleInvoiceClick = () => {
    history.push(`/invoice-list`);
  };


  const handleEstimateClick = () => {
    history.push(`/estimate-list`);
  };


  const handlePaymentClick = () => {
    history.push(`/payments-received`);
  };

  
  const forceMenuClose = () => {  
    setMenu(false);
  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} forceMenuClose={forceMenuClose} />
        <Sidebar />

        <div className="page-wrapper boardingwizard">
          <div className="content container-fluid">
            <div className="row">
            <div className="col-xl-3 col-sm-6 col-12" onClick={handlePaymentClick} style={{ cursor: 'pointer' }}>
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-1">
                        <i className="fas fa-dollar-sign"></i>
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">Amount Due</div>
                        <div className="dash-counts">
                          <p>{dashboarddata.symbol}{dashboarddata.outstand_bal}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12" onClick={handleCardClick} style={{ cursor: 'pointer' }}>
                
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-2">
                        <i className="fas fa-users"></i>
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">Clients</div>
                        <div className="dash-counts">
                          <p>{dashboarddata.customer}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12" onClick={handleInvoiceClick} style={{ cursor: 'pointer' }}>
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-3">
                        <i className="fas fa-file-alt"></i>
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">Invoices</div>
                        <div className="dash-counts">
                          <p>{dashboarddata.invoice}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12" onClick={handleEstimateClick} style={{ cursor: 'pointer' }}>
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-4">
                        <i className="far fa-file"></i>
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">Estimates</div>
                        <div className="dash-counts">
                          <p>{dashboarddata.estimate}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-7 d-flex">
                <div className="card flex-fill">
                  <div className="card-header">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="card-title">Monthly Sales & Receipts</h5>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                      <div className="w-md-100 d-flex align-items-center mb-3 flex-wrap">
                        <div>
                          <span>Total Sales</span>
                          <p className="h5 text-primary me-5">
                            {dashboarddata.symbol}{dashboarddata.totalsales}
                          </p>
                        </div>
                        <div>
                          <span>Receipts</span>
                          <p className="h5 text-success me-5">
                            {dashboarddata.symbol}{dashboarddata.recipts}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div id="sales_chart"></div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 d-flex">
                <div className="card flex-fill">
                  <div className="card-header">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="card-title">Payment Summary</h5>
                    </div>
                  </div>
                  <div className="card-body">
                    <div id="invoice_chart"></div>
                    <div className="text-center text-muted">
                      <div className="row">
                        <div className="col-4">
                          <div className="mt-4" onClick={handleInvoiceClick} style={{ cursor: 'pointer' }}>
                            <p className="mb-2 text-truncate">
                              <i className="fas fa-circle text-primary me-1"></i>{" "}
                              Invoiced
                            </p>
                            <h4 className="newfsize">{dashboarddata.symbol}{dashboarddata.totalsales}</h4>
                          </div>
                        </div>
                        <div className="col-4">
                        <div className="mt-4" onClick={handlePaymentClick} style={{ cursor: 'pointer' }}>
                            <p className="mb-2 text-truncate">
                              <i className="fas fa-circle text-success me-1"></i>{" "}
                              Receipts
                            </p>
                            <h4 className="newfsize">{dashboarddata.symbol}{dashboarddata.recipts}</h4>
                          </div>
                        </div>
                        <div className="col-4">
                        <div className="mt-4" onClick={handleInvoiceClick} style={{ cursor: 'pointer' }}>
                            <p className="mb-2 text-truncate">
                              <i className="fas fa-circle text-danger me-1"></i>{" "}
                              Pending
                            </p>
                            <h4 className="newfsize">{dashboarddata.symbol}{dashboarddata.pending}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 d-flex">
                <div className="card card-table flex-fill">
                  <div className="card-header">
                    <h5 className="card-title">Recent Invoices</h5>
                  </div>
                  <div className="card-body">
                    {invoices.length === 0 ? (
                      <p>No Invoices Available</p>
                    ) : (
                      <div className="table-responsive">
                        <table className="table table-hover table-center">
                          <thead>
                            <tr>
                             
                              <th>Client</th>
                              <th>Date</th>
                              <th>Amount</th>
                            
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {invoices.map((invoice) => (
                              <tr key={invoice.id}>
                               
                                <td>{invoice.client_name}</td>
                                <td>
                                  {moment(invoice.datetimestampdue).format("MMMM D, YYYY")}
                                </td>
                                <td>{dashboarddata.symbol}{invoice.gtotal}</td>
                               
                                <td className="text-right">
                                  <div className="dropdown dropdown-action">
                                    <a
                                      href="#"
                                      className="action-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fas fa-ellipsis-v"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        className="dropdown-item"
                                        onClick={() =>
                                          handleSubmenu("edit", "invoicelist", invoice)
                                        }
                                      >
                                        <i className="far fa-edit me-2"></i>Edit
                                      </a>
                                     
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex">
                <div className="card card-table flex-fill">
                  <div className="card-header">
                    <h5 className="card-title">Recent Estimates</h5>
                  </div>
                  <div className="card-body">
                    {estimates.length === 0 ? (
                      <p>No Estimates Available</p>
                    ) : (
                      <div className="table-responsive">
                        <table className="table table-hover table-center">
                          <thead>
                            <tr>
                            
                              <th>Client</th>
                              <th>Date</th>
                              <th>Amount</th>
                             
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>

                          {estimates.map((estimate) => (
  <tr key={estimate.id}>
                               
                                <td>{estimate.client_name}</td>
                                <td>
                                  {moment(estimate.datetimestampdue).format("MMMM D, YYYY")}
                                </td>
                                <td>{dashboarddata.symbol}{estimate.gtotal}</td>
                                
                                <td className="text-right">
                                  <div className="dropdown dropdown-action">
                                    <a
                                      href="#"
                                      className="action-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fas fa-ellipsis-v"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        className="dropdown-item"
                                        onClick={() =>
                                          handleSubmenu("edit", "estimatelist", estimate)
                                        }
                                      >
                                        <i className="far fa-edit me-2"></i>Edit
                                      </a>
                                     
                                    </div>
                                  </div>
                                </td>
                              </tr>
))}
                           
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
