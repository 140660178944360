const config = {
    API_URL: "https://poweredby.in/invoice_app/admin/webapi/",
    API_LOGOURL: "https://poweredby.in/invoice_app/upload/logo/",
    API_SIGNURL: "https://poweredby.in/invoice_app/upload/logo/",
  };



  
  
  
  export default config;