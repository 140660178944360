import React, { useState, useEffect, useRef } from "react";


// eslint-disable-next-line no-unused-vars
import Form from 'react-bootstrap/Form';

// eslint-disable-next-line no-unused-vars
import { useHistory, Link, useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";

import Select from "react-select";
import ClientService from '../services/client.service';
// eslint-disable-next-line no-unused-vars
import staticmessages from '../services/staticmessages';
import ItemService from '../services/item.service';

import InvoiceService from '../services/invoice.service';
// eslint-disable-next-line no-unused-vars
import { convertPercentageDiscount, calculateDiscountOnPercentage, formattedWithFractionLengthDouble, renderTaxData, returnDataValue, PaymentTerms, SubscriptionTerms , calculateTaxableAmountPerItem} from '../services/Helper';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


import * as yup from "yup";
import alertify from "alertifyjs";
import "../../node_modules/alertifyjs/build/css/alertify.css";
import "../../node_modules/alertifyjs/build/css/themes/semantic.css";
import ProfileSetting from "../components/ProfileSetting";

import NewCustomer from "../components/newcustomer";

import ConfirmationModal from '../common/modal/ConfirmationModal'; // Adjust the path



const AddInvoice = () => {

  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState({}); // State to hold validation errors
  const [show, setShow] = useState(false);

  const [refreshrecal, setRefreshRecalculate] = useState(false);

  const [showDel, setShowDel] = useState(false);


  const [showSelect, setShowSelect] = useState(false);
  const handleCloseSelect = () => setShowSelect(false);


  // eslint-disable-next-line no-unused-vars
  const [showUserProfile, setShowUserProfile] = useState(false);


 // eslint-disable-next-line no-unused-vars
  const handleClose = () => {
    setShow(false);
    setErrors({});
  };


  

  const handleCloseSelectItem = () => {
    setShowNewItem(false);
    setErrors({});
  };

  const [showNewItem, setShowNewItem] = useState(false);

  // eslint-disable-next-line no-unused-vars





  // eslint-disable-next-line no-unused-vars
  const [erroritem, setErrorItem] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [listType, setListType] = useState("invoicelist");
  // eslint-disable-next-line no-unused-vars
  const [customtitle, setCustomTitle] = useState(staticmessages.INVOICE_LIST_TITLE);

  const [displaytax, setDisplayTaxt] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isRecurring, setIsRecurring] = useState(false);
  const [subscriptionstartDate, setSubscriptionDate] = useState(null);

  const handleRecurringChange = (recurring) => {
    console.log("handleRecurringChange");
    console.log(recurring);

   // setIsRecurring(recurring === "yes");

    if (recurring === "no") {

      setInvoice(prevInvoice => ({
        ...prevInvoice,
        datetimestamprenewal: null,
        subscription_terms: "",
        isRecurring: false,

      }));
      setIsRecurring(false);
      setSubscriptionDate("");
      setSelectedSubscriptionTerm("");


    }
    else{
      setIsRecurring(true);
      setInvoice(prevInvoice => ({
        ...prevInvoice,
      
        isRecurring: true,

      }));

    }



  };




  const [selectedSubscriptionTerm, setSelectedSubscriptionTerm] = useState(null);
  const handleSubscriptionTermChange = (selectedOption) => {
    setSelectedSubscriptionTerm(selectedOption.target.value);



    setInvoice((prevEstimate) => ({
      ...prevEstimate,
      subscription_terms: selectedOption.target.value

    }));
    setSubscriptionDate(calculateSubscriptionDate(selectedOption.target.value));

  };



  useEffect(() => {





    setInvoice(prevInvoice => ({
      ...prevInvoice,
      datetimestamprenewal: subscriptionstartDate,

    }));

  }, [subscriptionstartDate]);

  // Function to calculate due date based on selected payment term
  const calculateSubscriptionDate = (term) => {
    if (!term) return "";

    const days = getSubscriptionTermDays(term);
    const dueDate = new Date();
    dueDate.setDate(dueDate.getDate() + days);

    if (isNaN(dueDate.getTime())) return "";


    setInvoice((prevEstimate) => ({
      ...prevEstimate,
      datetimestamprenewal: dueDate.getTime()
    }));

    return dueDate.getTime(); // Format as dd-mm-yyyy
  };

  const getSubscriptionTermDays = (term) => {
    switch (term) {
      case "Monthly":
        return 30;
      case "Quarterly":
        return 90; // Assuming 90 days in a quarter
      case "Half Yearly":
        return 180; // Assuming 180 days in half a year
      case "Yearly":
        return 365; // Assuming 365 days in a year
      default:
        return 0;
    }
  };






  // eslint-disable-next-line no-unused-vars
  const inputInvoiceValues = {
    id: null,
    uniqueno: '',
    client_name: '',
    invoice_number: '',
    order_no: '',
    invoice_prefix: "",
    date: Date.now(),
    order_date: null,
    due_date: "",
    renewal_date: "",
    datetimestamp: Date.now(),
    datetimestampdue: Date.now(),
    datetimestamprenewal: null,
    subtotal: 0,
    gtotal: 0,
    partial_amount: 0,
    globaltax: 0,
    conversion_rate: '',
    discount: '',
    discount_amount: '',
    discounttype: '',
    taxable_amount: '',
    invoice_taxrate: '',

    user_id: 0,
    symbol: '',

    payment_terms: 'DUE_ON_RECEIPT',
    subscription_terms: '',

    dateformat: '',
    currency: '',
    selectedtax: '',
    status: false,
    country: '',
    state: '',
    customer: null,
    customeritem: [],
    deletecustomeritem: [],
    taxdata: [],
    defaulttaxdata: [],
    termcondition: '',
    isRecurring: false,
  };
  // eslint-disable-next-line no-unused-vars


  const [invoice, setInvoice] = useState(location.state?.selectedinvoice || inputInvoiceValues);

  const [initialInvoice, setInitialInvoice] = useState(location.state?.selectedinvoice || inputInvoiceValues);

  const [selectedTerm, setSelectedTerm] = useState("DUE_ON_RECEIPT");

  const handleTermChange = (selectedOption) => {

    setSelectedTerm(selectedOption.target.value);

    console.log(selectedOption.target.value);

    setInvoice((prevEstimate) => ({
      ...prevEstimate,
      payment_terms: selectedOption.target.value

    }));


    setStartDate(calculateDueDate(selectedOption.target.value));
  };
  // useEffect(() => {
  //   console.log(calculateDueDate(selectedTerm));
  //   calculateDueDate(selectedTerm)

  //   setStartDate(calculateDueDate(selectedTerm));

  // }, [selectedTerm]);


  // Function to format date as dd-mm-yyyy
  // eslint-disable-next-line no-unused-vars
  const formatDate = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
      return "";
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };



  // Function to calculate due date based on selected payment term
  const calculateDueDate = (term) => {
    if (!term) return "";

    const days = getPaymentTermDays(term);
    const dueDate = new Date();
    dueDate.setDate(dueDate.getDate() + days);

    if (isNaN(dueDate.getTime())) return "";

    setInvoice((prevEstimate) => ({
      ...prevEstimate,
      datetimestampdue: dueDate.getTime()

    }));


    return dueDate; // Format as dd-mm-yyyy
  };

  const getPaymentTermDays = (term) => {
    switch (term) {
      case "DUE_ON_RECEIPT":
        return 0;
      case "NET_15":
        return 15;
      case "NET_30":
        return 30;
      case "NET_45":
        return 45;
      case "NET_60":
        return 60;
      case "CUSTOM_DATE":
        return 0; // Custom logic can be applied here
      default:
        return 0;
    }
  };












  useEffect(() => {

    const fetchDataAndInitialize = async () => {
      try {

        if (location.state?.selectedinvoice) {
          const selectedInvoice = location.state.selectedinvoice;


         

          if (selectedInvoice.customeritems) {
            console.log("selectedinvoice 555 FFF 222");
            selectedInvoice.customeritems.forEach(item => {
              const updatedItem = calculateItemTotal(item);

              console.log("selectedinvoice 555 FFF" + JSON.stringify(updatedItem, null, 2));

              setAddItemtoCart(prevCart => [...prevCart, updatedItem]);
            });


          }



          const selectedPaymentTerms = PaymentTerms.find(option => option.value === selectedInvoice.payment_terms);
          // const selectedPaymentTerms = PaymentTerms.find(option => option.value === "DUE_ON_RECEIPT");

        
          console.log(selectedPaymentTerms);
          if (selectedPaymentTerms) {
            setSelectedTerm(selectedPaymentTerms.value);
          }

          const selectedSubsTerms = SubscriptionTerms.find(option => option.value === selectedInvoice.subscription_terms);
          //const selectedSubsTerms = SubscriptionTerms.find(option => option.value === "Yearly");

          console.log("selectedinvoice555FFF 111=="+JSON.stringify(selectedInvoice.datetimestamprenewal, null, 2));

          console.log("selectedinvoice555FFF 222=="+JSON.stringify(selectedPaymentTerms, null, 2));


          if (selectedSubsTerms) {
            setSelectedSubscriptionTerm(selectedSubsTerms.value);
          }


          setSelectedPercentage({
            value: selectedInvoice.discounttype,
            label: selectedInvoice.discounttype
          });


          const selectedOptiontax = selectedtax.find(option => option.value === selectedInvoice.selectedtax);
          setSelectedTax(selectedOptiontax);

          setSelectedClient({
            value: selectedInvoice.customer?.id || '',
            label: selectedInvoice.customer?.client_name || '',
          });

          setInvoice(selectedInvoice);

          setInvoice(prevInvoice => ({
            ...prevInvoice,
            customeritem: selectedInvoice.customeritem,

          }));


          setInitialInvoice(selectedInvoice);

          setInitialInvoice(prevInvoice => ({
            ...prevInvoice,
            customeritem: selectedInvoice.customeritem,

          }));

          setStartInvoiceDate(selectedInvoice.datetimestamp);
          setStartDate(selectedInvoice.datetimestampdue);
          if (selectedInvoice.datetimestamprenewal > 0) {
            setSubscriptionDate(selectedInvoice.datetimestamprenewal);
            setIsRecurring(true);
          }
          else {
            setSubscriptionDate(null);
            setIsRecurring(false);
          }


          await fetchData(); // Reuse the fetchData function
         
          initDefalutValues(selectedInvoice);
        }
      }
    catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      // Fetch data and initialize if selectedInvoice is present
      fetchDataAndInitialize();

    }, [location.state?.selectedinvoice]);



  // Centralized function to fetch data
  const fetchData = async () => {
    try {
      await getDefaultTax();   // Priority 6
      await fetchClients();    // Priority 1
      await fetchItems();      // Priority 2
      await fetchCategories(); // Priority 3
      await fetchUnits();      // Priority 4
      await getUser();         // Priority 5
     
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // Fetch data on component mount
    fetchData();
  }, []);


 const [newtax, setNewInitTax] = useState([]);


  const [taxlabel, setEditTaxLabel] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const getDefaultTax = async () => {
    try {

      let res = await InvoiceService.getDefaultTax();
      setNewInitTax(res.data);
      setEditTaxLabel(res.data);

      console.log("TaxMAster debug 00000---getDefaultTax: " + JSON.stringify(res, null, 2));
      console.log("checkTaxLabel 00000---taxdata:" + JSON.stringify(invoice.taxdata, null, 2));
      console.log("TesthandleSaveAction 00000---taxarray:" + JSON.stringify(invoice.taxarray, null, 2));
      console.log("TesthandleSaveAction 00000---selectedinvoice: " + JSON.stringify(invoice, null, 2));

      console.log("CountFire 1111"+ JSON.stringify(invoice.taxdata, null, 2));
      console.log("CountFire 1111aa"+ JSON.stringify(invoice.defaulttaxdata, null, 2));

      setInvoice(prevInvoice => ({
        ...prevInvoice,
        taxdata: res.data,
        defaulttaxdata: res.data,
      }));

      console.log("CountFire 1111bb"+ JSON.stringify(invoice.defaulttaxdata, null, 2));

    } catch (error) {
      console.error('Login failed:', error);
    }
  };





  const inputValues = {
    id: null,
    item_name: '',
    hsn_sac: '',
    category: '',
    unit: '',
    taxrate: '',
    price: '',
    org_price: '',
    quantity: '', // New field
    discount: '',
    discountamt: '',
    itemdiscounttype: '', // New field
    discountValue: '', // New field
    totalprice: '', // New field

  };

  useEffect(() => {
    console.log("useEffect useEffectgetInvoices " + invoice);
    console.log(invoice);

    switch (location.pathname) {
      case '/add-invoice':
        setListType('invoicelist');
        setCustomTitle(staticmessages.INVOICE_LIST_TITLE);
        loadInvoiceNo('invoicelist');
        break;
      case '/edit-invoice':
        setListType('invoicelist');
        setCustomTitle(staticmessages.INVOICE_LIST_TITLE);
        break;

      case '/add-estimate':
        setListType('estimatelist');
        setCustomTitle(staticmessages.ESTIMATE_LIST_TITLE);
        loadInvoiceNo('estimatelist');
        break;
      case '/edit-estimate':
        setListType('estimatelist');
        setCustomTitle(staticmessages.ESTIMATE_LIST_TITLE);
        break;

      default:

    }



  }, [location.pathname]);


  // eslint-disable-next-line no-unused-vars

  // eslint-disable-next-line no-unused-vars

  const [menu, setMenu] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const [startInvoiceDate, setStartInvoiceDate] = useState(new Date());
  // eslint-disable-next-line no-unused-vars
  const [startOrderDate, setStartOrderDate] = useState(null);

  const [clients, setClients] = useState([]);
  const [items, setItems] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [itemtocart, setAddItemtoCart] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [item, setItem] = useState(inputValues);

  // eslint-disable-next-line no-unused-vars
  const [edititem, setEditItem] = useState(inputValues);
  // eslint-disable-next-line no-unused-vars

  const [delitem, SetDeleteItem] = useState(null);

  const [deletecustomeritem, setDeleteCustomerItem] = useState([]);

  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);

  const [currformat, setCurrFormat] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [showTaxDisplay, setShowTaxDisplay] = useState(null);


  // eslint-disable-next-line no-unused-vars
  const [createnewitem, setCreateNewItem] = useState(inputValues);




  // eslint-disable-next-line no-unused-vars
  const openitemlist = () => {
    setShowSelect(true);
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };


  const HandaleInvoiceDate = (value) => {


    if (value) {
      setStartInvoiceDate(value);
      setInvoice(prevInvoice => ({
        ...prevInvoice,
        datetimestamp: value.getTime(),

      }));

    }



  };


  const HandaleOrderDate = (value) => {
    setStartOrderDate(value);
    setInvoice(prevInvoice => ({
      ...prevInvoice,
      order_date: value.getTime(),

    }));
  };

  const HandaleDueDate = (value) => {
    setStartDate(value);
    setInvoice(prevInvoice => ({
      ...prevInvoice,
      datetimestampdue: value.getTime(),

    }));
  };


  const HandaleSubscriptionDate = (value) => {
   


    const renDate = new Date(value);
    setSubscriptionDate(renDate.getTime());
    setInvoice(prevInvoice => ({
      ...prevInvoice,
      datetimestamprenewal: renDate.getTime(),

    }));
  };


  const alertPopProfile = async (data) => {

    if (data.data.country == "") {
      setShowUserProfile(true);
    }

    if (data.data.symbol == "") {
      setShowUserProfile(true);
    }

  };

  const getUser = async () => {
    try {
      let res = await InvoiceService.getUserProfile();
      setCurrFormat(res.data.symbol);

      alertPopProfile(res)

      initDefalutValues(res);

      console.log("getUser=======");
      console.log(res.data.symbol);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  useEffect(() => {

    // showTaxDisplay
    if (invoice.selectedtax == "peritem") {
      setShowTaxDisplay(true);
    }
    else {
      setShowTaxDisplay(false);
    }

  }, [invoice.selectedtax]);


  const initDefalutValues = (userdata) => {

    
    console.log(invoice);

    if (invoice.id || invoice.uniqueno) {
      console.log("Helper =======" + invoice.id);

      setInvoice(prevInvoice => ({
        ...prevInvoice,
        defaulttaxdata: invoice.taxdata,

      }));



      const selectedOptiontax = selectedtax.find(option => option.value === invoice.selectedtax);
      setSelectedTax(selectedOptiontax);


      const selectedOption = percentage.find(option => option.value === invoice.discounttype);

      setSelectedPercentage(selectedOption)


      if (invoice.order_date > 0) {
        setStartOrderDate(invoice.order_date);
      }
      else {
        setStartOrderDate(null);
      }

      if (invoice.datetimestamprenewal > 0) {
        setIsRecurring(true);
        setSubscriptionDate(invoice.datetimestamprenewal);
      }
      else {
        setIsRecurring(false);
        setSubscriptionDate(null);
      }

      setInvoice(prevInvoice => ({
        ...prevInvoice,
        taxarray: invoice.taxdata,

      }));

      setInitialInvoice(prevInvoice => ({
        ...prevInvoice,
        taxarray: invoice.taxdata,

      }));

     

      console.log("CountFire 666666"+invoice.selectedtax);

    }
    else {
      setInvoice(prevInvoice => ({
        ...prevInvoice,
        symbol: userdata.data.symbol,
        currency: userdata.data.currency,
        dateformat: userdata.data.dateformat,
        selectedtax: userdata.data.selectedtax,
        invoice_taxrate: userdata.data.taxrate,
        country: userdata.data.country,
        state: userdata.data.state,

        deletecustomeritem: [],
      }));

      setInitialInvoice(prevInvoice => ({
        ...prevInvoice,
        symbol: userdata.data.symbol,
        currency: userdata.data.currency,
        dateformat: userdata.data.dateformat,
        selectedtax: userdata.data.selectedtax,
        invoice_taxrate: userdata.data.taxrate,
        country: userdata.data.country,
        state: userdata.data.state,

        deletecustomeritem: [],
      }));



      const selectedOptiontax = selectedtax.find(option => option.value === userdata.data.selectedtax);
      setSelectedTax(selectedOptiontax);
      console.log("=======SSSSSSSSS ADDDD");
      console.log(userdata.data.state);
    }


  };





  const loadInvoiceNo = async (listType) => {
    try {
      const response = await InvoiceService.loadInvoiceNo(invoice.id, false, listType);


      setInvoice(prevInvoice => ({
        ...prevInvoice,
        invoice_number: response.invoice_number
      }));

      setInitialInvoice(prevInvoice => ({
        ...prevInvoice,
        invoice_number: response.invoice_number
      }));

    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };


  const fetchItems = async () => {
    try {
      const response = await ItemService.getItems();
      setItems(response.data);

    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const fetchClients = async () => {
    try {
      const response = await ClientService.getClients();
      setClients(response.data);

    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const fetchCategories = () => {
    ItemService.fetchCategories()
      .then((response) => {
        const options2 = response.data.map((item) => ({
          value: item.name,
          label: item.name,
        }));
        console.error(options2);
        setCategories(options2);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  };

  const fetchUnits = () => {
    ItemService.fetchUnits()
      .then((response) => {
        const tempunits = response.data.map((item) => ({
          value: item.name,
          label: item.name,
        }));

        console.error(tempunits);
        setUnits(tempunits);
      })
      .catch((error) => {
        console.error('Error fetching units:', error);
      });
  };







  const calculateItemTotalCreateNewItem = (createnewitem) => {
    // Convert relevant fields to numbers if needed
    const price = parseFloat(createnewitem.price);
    const quantity = createnewitem.quantity ? parseFloat(createnewitem.quantity) : 1;
    const taxrate = createnewitem.taxrate ? parseFloat(createnewitem.taxrate) : 0;
    const discountValue = createnewitem.discountValue ? parseFloat(createnewitem.discountValue) : 0;

    let discountamt = 0;

    // Calculate total based on price, quantity, tax, and discount
    let totalprice = price * quantity;

    if (!isNaN(taxrate)) {
      // totalprice *= (1 + taxrate / 100); // Apply tax if taxrate is valid
    }




    if (createnewitem.itemdiscounttype === true && !isNaN(discountValue)) {
      const discountAmount = totalprice * (discountValue / 100);

      discountamt = parseFloat(discountAmount);


      totalprice -= discountAmount; // Apply percentage discount
    } else if (createnewitem.itemdiscounttype === false && !isNaN(discountValue)) {
      totalprice -= discountValue; // Apply flat discount

      discountamt = discountValue;

    }
    else {
      totalprice;
    }

    console.log("calculateItemTotalCreateNewItem");
    console.log(createnewitem);
    console.log(price + '--' + quantity + '===' + totalprice);

    // Round totalprice to 2 decimal places
    totalprice = Math.round(totalprice * 100) / 100;



    setCreateNewItem({
      ...createnewitem, ['totalprice']:
        totalprice,
      discount: discountValue,
      org_price: createnewitem.price ? createnewitem.price : createnewitem.price,
      discountamt: discountamt
    });

    return {
      ...createnewitem, totalprice: totalprice,

      discount: discountValue,
      org_price: createnewitem.org_price ? createnewitem.org_price : createnewitem.price,
      discountamt: discountamt
    };
  };


  useEffect(() => {

    calculateItemTotalCreateNewItem(createnewitem);

  }, [createnewitem.price, createnewitem.taxrate, createnewitem.discountValue, createnewitem.itemdiscounttype, createnewitem.quantity]);

  useEffect(() => {

    calculateItemTotal(item);

  }, [item.price, item.taxrate, item.discountValue, item.itemdiscounttype, item.quantity]);



  const opennewitem = () => {
    setShowNewItem(true);
    setCreateNewItem({});

    setCreateNewItem(prevState => ({
      ...prevState,
      quantity: 1
    }));

  };



  // eslint-disable-next-line no-unused-vars
  const handleChangeDiscountNewItem = (name, value) => {
    // Find the selected option based on the value
    const selectedOption = percentage.find(option => option.value === value);

    // Update the selected item discount state
    setSelectedItemDiscount(selectedOption);

    console.log(`handleChangeDiscountItem name: ${name}, value: ${value.value}`);

    // Update the item state based on the selected discount type
    if (value.value === "Percentage") {
      setCreateNewItem(prevItem => ({
        ...prevItem,
        [name]: true
      }));
    }
    else if (value.value === "Flat") {
      setCreateNewItem(prevItem => ({
        ...prevItem,
        [name]: false
      }));
    }
    else {
      setCreateNewItem(prevItem => ({
        ...prevItem,
        [name]: null,
        discountValue: 0,
        discount: 0,
        itemdiscount: 0,
        itemdiscountamt: 0,
        discountamt: 0,
      }));
    }

    if (value.value === "nodiscount") {
      setCreateNewItem(prevItem => ({
        ...prevItem,
        discountValue: 0,
        discount: 0,
        itemdiscount: 0,
        itemdiscountamt: 0,
        discountamt: 0,
      }));
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleChangeNewItem = (e) => {
    const { name, value } = e.target;
    setCreateNewItem({ ...createnewitem, [name]: value });
  };

  // eslint-disable-next-line no-unused-vars
  const handleChangeNewItemDecimalVal = (e) => {
    const { name, value } = e.target;

    // Allow numbers and decimal points only
  const regex = /^\d*\.?\d*$/;

  if (value === "" || regex.test(value)) {
    // Update the item state only if the input is valid
    setCreateNewItem((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  }
    
   // setCreateNewItem({ ...createnewitem, [name]: value });
  };


  // eslint-disable-next-line no-unused-vars
  // const handleChangeCustomNewItem = (value, name) => {
  //   setCreateNewItem({ ...createnewitem, [name]: value.value });
  // };
  const handleChangeCustomNewItem = (selectedOption, name) => {
    // Check if selectedOption is null before accessing its properties
    const newValue = selectedOption ? selectedOption.value : null; 
    setCreateNewItem({ ...item, [name]: newValue });
  };


  // eslint-disable-next-line no-unused-vars
  const formattedOptionsClientsNewItem = (options) =>
    options.map((option) => ({
      value: option.id,
      label: option.client_name,
    }));




    const handleChangeDecimalVal = (e) => {
      const { name, value } = e.target;
  
      // Allow numbers and decimal points only
    const regex = /^\d*\.?\d*$/;
  
    if (value === "" || regex.test(value)) {
      // Update the item state only if the input is valid
      setItem((prevItem) => ({
        ...prevItem,
        [name]: value,
      }));
    }
      
     //  setItem({ ...item, [name]: value });
    };

  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    setItem((prevItem) => ({
      ...prevItem,
      [name]: value,
      ['org_price']: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItem({ ...item, [name]: value });
  };



  const handleChangeCustom = (value, name) => {
    setItem({ ...item, [name]: value.value });
  };

  const handleChangeCustomUnit = (selectedOption, name) => {
    // Check if selectedOption is null before accessing its properties
    const newValue = selectedOption ? selectedOption.value : null; 
    setItem({ ...item, [name]: newValue });
  };


  const formattedOptionsClients = (options) =>
    options.map((option) => ({
      value: option.id,
      label: option.client_name,
    }));

  // eslint-disable-next-line no-unused-vars
  const formattedOptionsItems = (options) =>
    options.map((option) => ({
      value: option.item_name,
      label: option.item_name,
    }));


  // eslint-disable-next-line no-unused-vars
  const [selectedClientDetails, setSelectedClientDetails] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [selectedClient, setSelectedClient] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [selectedProducts, setSelectedProducts] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [selectedPercentage, setSelectedPercentage] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [selectedItemDiscount, setSelectedItemDiscount] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [selectedTax, setSelectedTax] = useState(null);





  const percentage = [
    { value: "nodiscount", label: "No Discount" },
    { value: "Percentage", label: "Percentage" },
    { value: "Flat", label: "Flat" },
  ];

  const selectedtax = [
    { value: "none", label: "No Tax" },
    { value: "ontotal", label: "On Total" },
    { value: "peritem", label: "Per Item" },
  ];



  const formattedOptions = (options) =>
    options.map((option) => ({
      value: option.value,
      label: option.label,
    }));

  const handleCustomerChange = (selectedOption) => {

    console.log(invoice);

    if (clients.length > 0) {
      // Find client with id 439
      const findclient = clients.find(client => client.id === selectedOption.value);

      console.log(findclient);


      if (findclient) {
        setSelectedClientDetails(findclient);

        setInvoice((prevEstimate) => ({
          ...prevEstimate,
          customer: findclient, client_name: selectedOption.label,

        }));

        console.log(invoice);

        //setInvoice({ ...invoice, customer: findclient , client_name: selectedOption.label });
      }
    }



    setSelectedClient(selectedOption);
    // Additional logic if needed
  };

  // eslint-disable-next-line no-unused-vars
  const handleProductsChange = (selectedOption) => {
    setSelectedProducts(selectedOption);
    // Additional logic if needed
  };



  useEffect(() => {
    let elements = Array.from(
      document.getElementsByClassName("react-datepicker-wrapper")
    );
    elements.map((element) => element.classList.add("w-100"));
  }, []);


  useEffect(() => {
    console.log("------itemtocart ===== " + itemtocart.length);
    const totalSum = itemtocart.reduce((acc, item) => acc + item.totalprice, 0);
    invoice.subtotal = totalSum
    //invoice.gtotal = totalSum

    console.log(itemtocart);

    console.log("handleUpdateItemhandleUpdateItemhandleUpdateItem calculateItemTotal 00000---" + JSON.stringify(itemtocart, null, 2));



    setInvoice((prevEstimate) => ({
      ...prevEstimate,
      customeritem: itemtocart,

    }));

    //  setInvoice({ ...invoice, customeritem: itemtocart });

  }, [itemtocart]);




  // eslint-disable-next-line no-unused-vars
  const createNewItemAction = (createnewitem) => {

    console.log("createnewitem 111");
    const schemaitem = yup.object().shape({
      quantity: yup.string().required('Quantity is required'),
      category: yup.string().required('Category is required'),
      item_name: yup.string().required('Item name is required'),
      price: yup.string()
        .matches(/^\d+(\.\d+)?$/, 'Price can only contain numeric characters and an optional decimal point')
        .required('Price is required'),


        
        itemdiscounttype: yup
        .string()
        .nullable()
        .test('itemdiscounttype-valid', 'Discount Type is required if Discount is provided', function (value) {
          const { discountValue } = this.parent;
          console.log("reloadCalculation11----" + discountValue +" --- "+value);
    
          if ((discountValue <= 0 || discountValue === '')) {
            return true; // Order No. is required if Order Date is provided and greater than 0
          }
          if (value && Number(value) > 0 && (!discountValue || discountValue === '')) {
            return false; // Order No. is required if Order Date is provided and greater than 0
          }
          if (discountValue && (!value || Number(value) <= 0)) {
            return false; // Order Date is required if Order No. is provided and must be greater than 0
          }
          return true;
        }),
    
        discountValue: yup
        .string()
        .nullable()
        .test('discountValue-no-valid', 'Discount is required if Discount Type is provided', function (value) {
          const { itemdiscounttype } = this.parent;
          console.log("reloadCalculation22----" + itemdiscounttype +" --- "+value);

        
          if (itemdiscounttype &&  Number(value) < 0) {
            return false; // Order No. is required if Order Date is provided
          }

          return true;
        }),

      taxrate: yup
        .string()
        .matches(/^(?:\d*\.?\d+)?$/, 'Tax Rate can only contain numeric characters and an optional decimal point')
        .nullable()
        .test('is-empty-or-valid-number', 'Tax Rate must be less than or equal to 100', function (value) {
          if (value === '' || value === undefined || value === null) {
            return true;
          }
          const numberValue = parseFloat(value);
          return !isNaN(numberValue) && numberValue <= 100;
        })

    });

    console.log("createnewitem 2222");
    schemaitem.validate(createnewitem, { abortEarly: false })


      .then(() => {
        console.log("createnewitem 3333");
        ItemService.updateItem('', createnewitem)
          .then((response) => {
            setErrors({});
            console.log("createnewitem 666 000");
            console.log(response.data[0]);
            if (response.data[0].id) {
              console.log("createnewitem 666 1111");

              const updatedItem = {
                ...createnewitem,
                id: response.data[0].id,
                org_price: response.data[0].price
              };

              setCreateNewItem(updatedItem);
           

              toastrSuccess(staticmessages.ITEM_USUCCESS);
              fetchItems();
              console.log("createnewitem 666 222----");
              // const updatedItem = calculateItemTotal(createnewitem);
              // console.log("createnewitem 666");
              console.log(updatedItem);
              setAddItemtoCart(prevCart => [...prevCart, updatedItem]);

              handleCloseSelectItem();


            }
            if (response.errorMessage) {
              toastrError(response.errorMessage);
              setErrorItem(response.errorMessage);
              setErrors({ ...errors, ['item_name']: response.errorMessage });

              console.log(response.errorMessage);

            }

            console.error(response);

          })
          .catch((error) => {
            console.log(error.message);
            setErrors({ ...errors, 'item_name': error.message });
            console.error('Error fetching units:', error);
          });


      })
      .catch((validationErrors) => {
        // Yup validation errors
        const errorsObj = {};
        validationErrors.inner.forEach((error) => {
          errorsObj[error.path] = error.message;
        });
        setErrors(errorsObj);
        console.log("createnewitem 5555");
        console.log(errorsObj);
      });






    console.log("createnewitem");
    console.log(createnewitem);

  }


  // eslint-disable-next-line no-unused-vars
  const [isNewSelectItem, setIsNewSelectItem] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [previousdiscount, setPreviousDiscount] = useState(null);



  // eslint-disable-next-line no-unused-vars
  const getPrevoisItemRecord = async (isNewSelectItem) => {
    try {
      console.log(selectedClient)

      let res = await InvoiceService.customerInvoicesGetOldItemOrder(isNewSelectItem, selectedClient.value);


      console.log("getDefaultTaxgetDefaultTax")
      console.log(res)

      setPreviousDiscount(res.data.messagetext);

      setIsNewSelectItem(null);


    } catch (error) {
      console.error('Login failed:', error);
    }
  };


  useEffect(() => {
    if (isNewSelectItem) {
      getPrevoisItemRecord(isNewSelectItem);
    }


  }, [isNewSelectItem]);


  // eslint-disable-next-line no-unused-vars
  const handleCheckboxChangePopup = (id) => {
    // eslint-disable-next-line no-unused-vars
    setIsNewSelectItem(id);
    setPreviousDiscount(null);

    // eslint-disable-next-line no-unused-vars
    const updatedItems = items.map((item) => {
      if (item.id === id) {

      

        // eslint-disable-next-line no-unused-vars
        const updatedItem = calculateItemTotal(item);
        setInitialItem(updatedItem);

      }

      return { ...item, isSelected: !item.isSelected }; // Update selection state
    });



    setShow(true); // Show the modal or any other UI

  }




  // eslint-disable-next-line no-unused-vars
  const handleCheckboxChange = (id) => {
    // eslint-disable-next-line no-unused-vars
    let itemExists = false;
    // eslint-disable-next-line no-unused-vars
    const updatedItems = items.map((item) => {
      if (item.id === id) {
        const updatedItem = calculateItemTotal(item);

        // Check if item with the same id already exists in setAddItemtoCart
        setAddItemtoCart((prevCart) => {
          // eslint-disable-next-line no-unused-vars
          const existingItemIndex = prevCart.findIndex((cartItem) => cartItem.id === id);
          if (existingItemIndex !== -1) {
            // Update existing item
            const updatedCart = [...prevCart];
            updatedCart[existingItemIndex] = updatedItem;
            return updatedCart;
          } else {
            // Add new item
            return [...prevCart, updatedItem];
          }
        });

        setShow(true); // Show the modal or any other UI
        itemExists = true; // Mark that item exists
      }

      return { ...item, isSelected: !item.isSelected }; // Update selection state
    });

    setItems(updatedItems);
  };

  // eslint-disable-next-line no-unused-vars
  const reloadCalculation = () => {
    // handleUpdateItem();
    console.log("reloadCalculation----" + invoice.subtotal);

    console.log("reloadCalculation: " + JSON.stringify(invoice, null, 2));

    calculateTotalDiscount(invoice.subtotal, invoice.discount, invoice.discounttype);

    setRefreshRecalculate(false);
  };

  useEffect(() => {

    reloadCalculation();
  }, [item.price, item.discountValue, item.taxrate, item.itemdiscounttype, item.quantity, item.item_name, item.category, item.unit, item.hsn_sac,
  invoice.discounttype, invoice.discount, invoice.invoice_taxrate, invoice.selectedtax,
  itemtocart.length, invoice.subtotal, refreshrecal, invoice.gtotal]); // Call handleUpdateItem whenever item changes





  // eslint-disable-next-line no-unused-vars
  const handleUpdateItem = () => {

    setAddItemtoCart((prevItemtocart) =>
      prevItemtocart.map((cartItem) =>
        cartItem.id === item.id
          ? { ...calculateItemTotal(item), id: cartItem.id } // Update matching item
          : cartItem // Leave other items unchanged
      )
    );

    const totalSum = itemtocart.reduce((acc, item) => acc + item.totalprice, 0);
    setInvoice((prevEstimate) => ({
      ...prevEstimate,
      subtotal: totalSum,
    }));

  };


  const toastrError = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.error(message);
  };
  const toastrSuccess = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.success(message);
  };


  const schema = yup.object({

    

    invoice_number: yup.string().required(() => `${customtitle} No. is required`),

    payment_terms: yup.string().required(() => `Payment Terms is required`),
    order_date: yup
    .string()
    .nullable()
    .test('order-date-valid', 'Order Date is required if Order No. is provided', function (value) {
      const { order_no } = this.parent;
      console.log("reloadCalculation11----" + order_no +" --- "+value);

      if ((!order_no || order_no === '')) {
        return true; // Order No. is required if Order Date is provided and greater than 0
      }
      if (value && Number(value) > 0 && (!order_no || order_no === '')) {
        return false; // Order No. is required if Order Date is provided and greater than 0
      }
      if (order_no && (!value || Number(value) <= 0)) {
        return false; // Order Date is required if Order No. is provided and must be greater than 0
      }
      return true;
    }),

  order_no: yup
    .string()
    .nullable()
    .test('order-no-valid', 'Order No. is required if Order Date is provided', function (value) {
      const { order_date } = this.parent;
      console.log("reloadCalculation22----" + order_date +" --- "+value);

      if (order_date && Number(order_date) > 0 && (!value || value === '')) {
        return false; // Order No. is required if Order Date is provided
      }
      return true;
    }),




    subscription_terms: yup
    .string()
    .nullable()
    .test('isRecurring-valid', 'Subscription Term is required.', function (value) {
      const { isRecurring } = this.parent;
      console.log("reloadCalculation11 subscription_terms----" + isRecurring +" --- "+value);

      if (isRecurring && (!value || value === '')) {
        return false; // Order No. is required if Order Date is provided and greater than 0
      }
     
      return true;
    }),

    discount: yup.string().when("discounttype", {
      is: (value) => ['Percentage', 'Flat'].includes(value),
      then: (s) => s
        .matches(/^\d+(\.\d+)?$/, 'Discount can only contain numeric characters and an optional decimal point')
        .test(
          'valid-discount',
          function (value) {
            const { discounttype } = this.parent;
            if (discounttype === 'Percentage') {
              if (!value || parseFloat(value) > 100) {
                return this.createError({
                  message: 'Discount% must be less than or equal to 100',
                });
              }
            }
            if (discounttype === 'Flat') {
              const { gtotal } = this.parent;
              if (!value || parseFloat(value) > gtotal) {
                return this.createError({
                  message: 'Discount Value must be less than Total Amount',
                });
              }
            }
            return true; // If no specific type or valid value, always valid
          }
        ),
      otherwise: (s) => s,
    }),




  });




  const handleCloseSelectCloseList = () => {
    console.log("createnewitem 5555");
    setShowSelect(false);

  }

  const handleCloseSelectClose = () => {
    handleClose();
    setShowSelect(false);

  }
 // eslint-disable-next-line no-unused-vars
  const handleSaveAction2 = () => {

    console.log("------handleSaveAction ===== ");
    console.log("TesthandleSaveAction 00000---" + JSON.stringify(invoice, null, 2));
    setErrors({});


    console.log("TesthandleSaveAction 00000---" + JSON.stringify(invoice, null, 2));
  };
  // eslint-disable-next-line no-unused-vars
  const handleSaveAction = () => {

    console.log("------handleSaveAction ===== ");

    setErrors({});


    console.log("TesthandleSaveAction 00000---" + JSON.stringify(invoice, null, 2));

    console.log("------customer ===== ");

    console.log(selectedClientDetails);

    schema.validate(invoice, { abortEarly: false })
      .then(() => {
        const action = invoice.id ? InvoiceService.updateInvoice : InvoiceService.addnewInvoice;




        action(invoice.id, invoice, listType)
          .then(() => {

            if (invoice.id) {
              let mess = `${customtitle} ${staticmessages.DEF_USUCCESS}`;
              toastrSuccess(mess);

            }
            else {
              let mess = `${customtitle} ${staticmessages.DEF_CSUCCESS}`;
              toastrSuccess(mess)
            }

            if (listType == "invoicelist") {
              history.push('invoice-list');
            }
            else {
              history.push('estimate-list');
            }


          })
          .catch((error) => {
            setError(error.message);
            toastrError(error.message);
          });
      })
      .catch((validationErrors) => {
        // Yup validation errors
        const errorsObj = {};
        validationErrors.inner.forEach((error) => {

          console.log(error.message);
          errorsObj[error.path] = error.message;
        });
        setErrors(errorsObj);
      });
  };

  // eslint-disable-next-line no-unused-vars
  const handleEditConfirm = (item) => {
    console.log("selectedinvoice 555 FFF handleEditConfirm 555 FFF" + JSON.stringify(item, null, 2));
    console.log("selectedinvoice 555 FFF handleEditConfirm 555 FFF itemdiscounttype" + item.itemdiscounttype);

    setItem(item);
    setInitialItem(item);

    if (item.itemdiscounttype == true) {
      const selectedOption = percentage.find(option => option.value === "Percentage");
      setSelectedItemDiscount(selectedOption);
    }
    else if (item.itemdiscounttype == false) {
      const selectedOption = percentage.find(option => option.value === "Flat");
      setSelectedItemDiscount(selectedOption);
    }
    else {
      setSelectedItemDiscount(null);
    }

    console.log(item)
    setShow(true);
  };

  // eslint-disable-next-line no-unused-vars
  const handleDeleteConfirm = (item) => {
    SetDeleteItem(item);
    console.log(item)


    setShowDel(true);
  };
  const DeleteItem = () => {


    // Filter out the item to delete
    // eslint-disable-next-line no-unused-vars
    const updatedItems = itemtocart.filter(i => i.id !== delitem.id);
    // Update state with the new array
    setAddItemtoCart(updatedItems);

    setDeleteCustomerItem([...deletecustomeritem, delitem]);

    console.log("DeleteItem");
    console.log(deletecustomeritem);

    const updatedInvoice = {
      ...invoice,
      deletecustomeritem: [...deletecustomeritem, delitem], // Update invoice with deletecustomeritem
    };
    setInvoice(updatedInvoice);

    setShowDel(false);

  };


  const handleChangeInvoice = (e) => {
    const { name, value } = e.target;

    console.log("handleUpdateItemhandleUpdateItemhandleUpdateItem---" + name);
    console.log("handleUpdateItemhandleUpdateItemhandleUpdateItem+++" + value);

    setInvoice({ ...invoice, [name]: value });



  };

  // eslint-disable-next-line no-unused-vars
  const handlePercentageChange = (name, value) => {

    const selectedOption = percentage.find(option => option.value === value.value);

    setSelectedPercentage(selectedOption)
    console.log("handleUpdateItemhandleUpdateItemhandleUpdateItem name---" + selectedOption);

    setInvoice((prevEstimate) => ({
      ...prevEstimate,
      ["discounttype"]: value.value,
    }));
    handleChangeInvoiceDiscountSeletedType(selectedOption);

  };


  const handleChangeInvoiceDiscountSeletedType = (selectedOption) => {



    console.log("handleChangeInvoiceDiscount 2222===" + selectedOption.value);


    if (invoice.discount == "nodiscount") {
      setInvoice({ ...invoice, ["discount"]: 0 });
    }

    if (selectedOption.value == "Percentage") {


      const discountValue = parseFloat(invoice.discount);

      if (discountValue > 100) {
        setInvoice({ ...invoice, ["discount"]: 0 });
        alert("Discount percentage cannot be greater than 100%.");
        return;
      }



    }


  };

  const handleChangeInvoiceDiscount = (e) => {
    const { name, value } = e.target;

    
    if (value == "nodiscount") {
      setInvoice({ ...invoice, ["discount"]: 0 });
    }

    if (selectedPercentage.value == "Percentage") {


      const discountValue = parseFloat(value);

      if (discountValue > 100) {

        
        setInvoice({ ...invoice, ["discount"]: 0 });
        alert("Discount percentage cannot be greater than 100%.");
        return;
      }



    }

    
    setInvoice({ ...invoice, [name]: value });

  };



  const handleChangeInvoiceTaxRate = (e) => {
    const { name, value } = e.target;

    console.log("zasasas handleChangeInvoiceTaxRate---" + value);

    // const regex = /^\d{0,2}(\.\d{0,2})?$/;

    if (value < 100) {
      setInvoice({ ...invoice, [name]: value ? value : 0 });
    } else {
      alert("Tax Rate cannot be greater than 100%..");
    }


  };


  // eslint-disable-next-line no-unused-vars
  const handleTaxChange = (name, value) => {
    console.log("zasasas handleUpdateItemhandleUpdateItemhandleUpdateItem selectedtax---" + value.value);

    const selectedOptiontax = selectedtax.find(option => option.value === value.value);

    setSelectedTax(selectedOptiontax);


    // showTaxDisplay
    if (value.value == "peritem") {
      setShowTaxDisplay(true);

      setInvoice((prevEstimate) => ({
        ...prevEstimate,
        ["discount"]: 0,
      
      }));
      
    }
    else {
      setShowTaxDisplay(false);
    }


    if (name == "selectedtax") {
      if (value != "ontotal") {

        setInvoice((prevEstimate) => ({
          ...prevEstimate,
          ["selectedtax"]: value.value,
          ["invoice_taxrate"]: 0,
        }));

      }
      else {
        setInvoice((prevEstimate) => ({
          ...prevEstimate,
          ["selectedtax"]: value.value,
        }));
      }

    }
 
      setInvoice((prevInvoice) => ({
        ...prevInvoice,
        taxdata: newtax,
        defaulttaxdata: newtax,
      }));
    

    // console.log("zasasas handleUpdateItemhandleUpdateItemhandleUpdateItem selectedtax---" + invoice.selectedtax);

  };





  // eslint-disable-next-line no-unused-vars
  const handleChangeDiscountItem = (name, value) => {
    // Find the selected option based on the value
    const selectedOption = percentage.find(option => option.value === value);

    // Update the selected item discount state
    setSelectedItemDiscount(selectedOption);

    console.log(`handleChangeDiscountItem name: ${name}, value: ${value.value}`);

    // Update the item state based on the selected discount type
    if (value.value === "Percentage") {
      setItem(prevItem => ({
        ...prevItem,
        [name]: true
      }));
    }
    else if (value.value === "Flat") {
      setItem(prevItem => ({
        ...prevItem,
        [name]: false
      }));
    }
    else {
      setItem(prevItem => ({
        ...prevItem,
        [name]: null,
        discountValue: 0,
        discount: 0,
        itemdiscountamt: 0,
        itemdiscount: 0,
        discountamt: 0,
      }));
    }

    if (value.value === "nodiscount") {
      setItem(prevItem => ({
        ...prevItem,
        discountValue: 0,
        discount: 0,
        itemdiscount: 0,
        itemdiscountamt: 0,
        discountamt: 0,
      }));
    }
  };




  // eslint-disable-next-line no-unused-vars
  const calculateTotalDiscount = (subtotal, discount, discountType) => {
    let discountAmount = 0;


    console.log("calculateTotalDiscount calculateTotalDiscount 00000---" + subtotal);

    if (discountType === 'Percentage') {
      discountAmount = (subtotal * discount) / 100;
    } else if (discountType === 'Flat') {
      discountAmount = discount;
    }

    let tableamount = invoice.subtotal - discountAmount;


    setInvoice((prevEstimate) => ({
      ...prevEstimate,
      discount_amount: discountAmount,
      taxable_amount: tableamount,
    }));


    console.log("ckkdev 111 calculateTotalDiscount 00000---" + JSON.stringify(invoice, null, 2));

    calculateTotalTax(subtotal, discountAmount, invoice.invoice_taxrate);

    return discountAmount;
  };



///// Resove IOS APP 111


  useEffect(() => {

    const updatedItem = calculateItemTotal(item);
   
    console.log("FFtotalpriceF calculateItemTotal 00000---zzz" + JSON.stringify(updatedItem, null, 2));

  }, [item.discountValue]);
///// Resove IOS APP 111




  const calculateItemTotal = (item) => {
    

    console.log("FFtotalpriceF calculateItemTotal 00000---" + JSON.stringify(item, null, 2));
    console.log(item);

    // Convert relevant fields to numbers if needed
    const price = parseFloat(item.org_price);

    const quantity = parseFloat(item.quantity);
    const taxrate = item.taxrate ? parseFloat(item.taxrate) : 0;
    let discountValue = parseFloat(item.discountValue) ? parseFloat(item.discountValue) : (parseFloat(item.itemdiscountamt) || 0);

    let discountamt = 0;
    // Calculate total based on price, quantity, tax, and discount
    let totalprice = price * quantity;

      ///// Resove IOS APP 111
    let discount = item.itemdiscounttype  ? discountValue : (discountValue / totalprice) * 100  ;
   
    //// Resove IOS APP 111


    


    if (!isNaN(taxrate)) {
      // totalprice *= (1 + taxrate / 100); // Apply tax if taxrate is valid
    }


    console.log(item.itemdiscounttype);


    if (item.itemdiscounttype === true && !isNaN(discountValue)) {
      const discountAmount = totalprice * (discountValue / 100);

      discountamt = parseFloat(discountAmount);

      console.log("selectedinvoice 555 FFtotalpriceF 111 ---" + totalprice);


      totalprice -= discountAmount; // Apply percentage discount
    } else if (item.itemdiscounttype === false && !isNaN(discountValue)) {
      totalprice -= discountValue; // Apply flat discount

      discountamt = discountValue;

      console.log("selectedinvoice 555 FFtotalpriceF 222 ---" + totalprice +"---"+discountValue);

    }
    else {
      console.log("selectedinvoice 555 FFtotalpriceF 333 ---" + totalprice);
      totalprice;
    }

    // Round totalprice to 2 decimal places
    totalprice = Math.round(totalprice * 100) / 100;


    console.log("selectedinvoice 555 FFtotalpriceF ---" + totalprice);

    setItem({
      ...item, ['totalprice']:
        totalprice,
      discount: discount ? discount : item.discount,
      price: price,
      org_price: item.org_price ? item.org_price : item.price,
      discountamt: discountamt ? discountamt : item.itemdiscountamt,
      discountValue: item.discountValue ? item.discountValue : 0,

    });

    console.log("FFtotalpriceF calculateItemTotal 00000---zzz 000--" + discount);

    return {
      ...item, totalprice: totalprice,

      discount: discount ? discount : item.discount,
      org_price: item.org_price ? item.org_price : item.price,

      price: item.price ? item.price : item.org_price,

     // price: item.org_price ? item.org_price : item.price,
      discountamt: discountamt ? discountamt : item.itemdiscountamt,
      discountValue: item.discountValue ? item.discountValue : 0,
    };
  };



  // eslint-disable-next-line no-unused-vars
  const calculateTotalTaxOnTotal = (subtotal, discountAmount, invoice_taxrate) => {
    let totalTaxAmount = 0;

    // eslint-disable-next-line no-unused-vars
    const itemTotal = itemtocart.reduce((acc, item) => acc + item.totalprice, 0);

    const itemTotalafterDiscount = itemTotal - discountAmount;
    // Parse item-wise tax rate to a float
    const itemTaxRateFloat = parseFloat(invoice_taxrate);

    // Validate itemTaxRate
    if (Number.isNaN(itemTaxRateFloat)) {
      totalTaxAmount = 0;
    } else {
      totalTaxAmount += (itemTotalafterDiscount * itemTaxRateFloat) / 100;
    }



    return totalTaxAmount;
  };

  // eslint-disable-next-line no-unused-vars
  const calculateTotalTaxPerItem = (subtotal, discountAmount, invoice_taxrate) => {
    /// PEr Item ------ ++++
    let percentageDiscount = 0;
    if (invoice.discounttype === "Percentage") {
      percentageDiscount = invoice.discount ?? 0.0
    }
    else {
      const totalSum = itemtocart.reduce((acc, item) => acc + item.totalprice, 0);
      percentageDiscount = convertPercentageDiscount(totalSum, invoice.discount);
    }
    console.log("xxxx calculateTotalTaxPerItemcalculateTotalTaxPerItem11111---" + invoice.discounttype + "--" + percentageDiscount);

    let totalTaxAmount = 0;
    let totaldiscount = 0;
    let totalTax = 0;


    itemtocart.map(item => {
      // eslint-disable-next-line no-unused-vars
      const { price, quantity, taxrate, totalprice, id } = item;
      const itemTotal = totalprice;

      let itemSubtotal = 0;
      let taxamt = 0;

      if (taxrate > 0) {
        if (invoice.discount > 0) {
          let itemDiscount = calculateDiscountOnPercentage(itemTotal, percentageDiscount ?? 0.0)


          itemSubtotal = itemTotal - itemDiscount
          totaldiscount = totaldiscount + itemDiscount



          let taxcalc = itemSubtotal * taxrate / 100
          taxamt = formattedWithFractionLengthDouble(taxcalc, 2, staticmessages.DEFINDIANDECIMALPLACE, invoice.country);


        }
        else {

          itemSubtotal = itemTotal
          let itemDiscount = 0.0
          itemSubtotal = itemTotal - itemDiscount
          totaldiscount = totaldiscount + itemDiscount



          let taxcalc = itemSubtotal * taxrate / 100
          taxamt = formattedWithFractionLengthDouble(taxcalc, 2, staticmessages.DEFINDIANDECIMALPLACE, invoice.country);

          console.log("zasasas calculateTotalTaxPerItemcalculateTotalTaxPerItem2222---AAAA---" + taxamt + "--" + taxrate);


        }
      }
      else {

        let itemDiscount = calculateDiscountOnPercentage(itemTotal, percentageDiscount ?? 0.0)


        itemSubtotal = itemTotal - itemDiscount
        totaldiscount = totaldiscount + itemDiscount

        taxamt = 0.0


      }
      totalTaxAmount += taxamt


    });
    /// PEr Item ------ XXXX

    console.log("zasasas calculateTotalTaxPerItemcalculateTotalTaxPerItem2222---" + totalTax + "--" + totalTaxAmount);


    return totalTaxAmount;
  }





  // eslint-disable-next-line no-unused-vars
  const calculateTotalTax = (subtotal, discountAmount, invoice_taxrate) => {




    // Parse invoice_taxrate to a float
    const taxRate = parseFloat(invoice_taxrate);

    let globalTaxAmount = 0;

    let totalTaxAmount = 0;



    // Check if taxRate is a number  // ontotal
    if (Number.isNaN(taxRate)) {
      console.error("Invalid invoice_taxrate value:", invoice_taxrate);
      globalTaxAmount = 0;
    } else {
      globalTaxAmount = ((subtotal - discountAmount) * taxRate) / 100;
    }



    // Determine total tax based on selected tax type
    let finalTaxAmount;
    switch (invoice.selectedtax) {
      case 'ontotal':{

       let taxableamountontotal = subtotal - discountAmount;
        setInvoice((prevEstimate) => ({
          ...prevEstimate,
          discount_amount: discountAmount,
          taxable_amount: taxableamountontotal,
        }));


        globalTaxAmount = calculateTotalTaxOnTotal(subtotal, discountAmount, invoice_taxrate);
        finalTaxAmount =   parseFloat(globalTaxAmount.toFixed(2));
        break;
      }
      case 'peritem':{

      let taxableamountperitem = calculateTaxableAmountPerItem(itemtocart, discountAmount);

        if(discountAmount > 0)
        {
          taxableamountperitem = taxableamountperitem - discountAmount;
        }
        console.log("calculateTaxableAmountPerItem 666 222----"+discountAmount);

      setInvoice((prevEstimate) => ({
        ...prevEstimate,
        discount_amount: discountAmount,
        taxable_amount: taxableamountperitem,
      }));


        totalTaxAmount = calculateTotalTaxPerItem(subtotal, discountAmount, invoice_taxrate);
        


        finalTaxAmount =  parseFloat(totalTaxAmount.toFixed(2));
        break;
    }
      default:{

      let taxableamount = subtotal - discountAmount;
      setInvoice((prevEstimate) => ({
        ...prevEstimate,
        discount_amount: discountAmount,
        taxable_amount: taxableamount,
      }));


        finalTaxAmount = 0;
        break;
    }
    
  }

    console.log("calculateTotalDiscount Check Tax Type11111---" + invoice.selectedtax + '+++' + subtotal + ':::' + totalTaxAmount);


    let gtotal = subtotal + finalTaxAmount - discountAmount;
    gtotal = parseFloat(gtotal.toFixed(2));

    setInvoice((prevEstimate) => ({
      ...prevEstimate,
      invoice_taxrate: taxRate,
      globaltax: finalTaxAmount,
      gtotal: gtotal,
    }));


    return globalTaxAmount;
  };

useEffect(() => {
  setInvoice((prevEstimate) => ({
    ...prevEstimate,
    taxdata: displaytax,
  }));
}, [displaytax]); // Ensure taxarray is in the dependency array


  // eslint-disable-next-line no-unused-vars
  const renderTaxDatalocal = () => {
    // Your logic to update taxdata

   

    if ((invoice.customer && invoice.defaulttaxdata) || (invoice.customer && invoice.taxdata)) {

      let taxarray = renderTaxData(invoice.defaulttaxdata, currformat, invoice, staticmessages.DEFINDIANDECIMALPLACE);

      console.log("TesthandleSaveAction 00000 555 FFF ---" + JSON.stringify(taxarray, null, 2));

      setDisplayTaxt(taxarray);

      setInvoice((prevEstimate) => ({
        ...prevEstimate,
        taxdata: taxarray,
      }));

      console.log("renderTaxDataDebug 555 FFF ---" + JSON.stringify(invoice.defaulttaxdata, null, 2));
      console.log("renderTaxDataDebug 555 FFF" + JSON.stringify(taxarray, null, 2));

      console.log("+++++++++++++++++++++++taxarray -----qqqqqq");

      console.log(taxarray);

      console.log("______________taxarray---qqqq");


      console.log("renderTaxDatalocal--" + taxarray.length);
      console.log("renderTaxDatalocal 22--" + invoice.taxdata.length);
      console.log("renderTaxDatalocal 333--" + invoice.selectedtax);
      console.log(taxarray);

      setInvoice((prevEstimate) => ({
        ...prevEstimate,
        taxdata: taxarray,
      }));

    }

  };

  useEffect(() => {
    console.log("Helper renderTaxDatalocalzzz--" + invoice.taxarray);
    console.log(invoice);
    renderTaxDatalocal();
  }, [invoice.gtotal, invoice.subtotal, invoice.taxdata.length, invoice.selectedtax, invoice.customer, invoice.customeritem, invoice.edittaxdata]);







  const handleCloseAction = (item) => {

    console.log("createnewitem 111");

    console.log("selectedinvoice 555 FFF handleCloseAction 00000---" + JSON.stringify(item, null, 2));

    const schemaitem = yup.object().shape({
      quantity: yup.string().required('Quantity is required'),
      category: yup.string().required('Category is required'),
      item_name: yup.string().required('Item name is required'),
      price: yup.string()
        .matches(/^\d+(\.\d+)?$/, 'Price can only contain numeric characters and an optional decimal point')
        .required('Price is required'),

      taxrate: yup
        .string()
        .matches(/^(?:\d*\.?\d+)?$/, 'Tax Rate can only contain numeric characters and an optional decimal point')
        .nullable()
        .test('is-empty-or-valid-number', 'Tax Rate must be less than or equal to 100', function (value) {
          if (value === '' || value === undefined || value === null) {
            return true;
          }
          const numberValue = value ? parseFloat(value) : 0;
          return !isNaN(numberValue) && numberValue <= 100;
        }),



        itemdiscounttype: yup
        .string()
        .nullable()
        .test('itemdiscounttype-valid', 'Discount Type is required if Discount is provided', function (value) {
          const { discountValue } = this.parent;
          console.log("reloadCalculation11----" + discountValue +" --- "+value);
    
          if ((discountValue <= 0 || discountValue === '')) {
            return true; // Order No. is required if Order Date is provided and greater than 0
          }
          if (value && Number(value) > 0 && (!discountValue || discountValue === '')) {
            return false; // Order No. is required if Order Date is provided and greater than 0
          }
          if (discountValue && (!value || Number(value) <= 0)) {
            return false; // Order Date is required if Order No. is provided and must be greater than 0
          }
          return true;
        }),
    
        discountValue: yup
        .string()
        .nullable()
        .test('discountValue-no-valid', 'Discount is required if Discount Type is provided', function (value) {
          const { itemdiscounttype } = this.parent;
          console.log("reloadCalculation22----" + itemdiscounttype +" --- "+value);

          
          if (itemdiscounttype &&  Number(value) < 0) {
            return false; // Order No. is required if Order Date is provided
          }
          return true;
        }),


        totalprice: yup
  .string()
  .matches(/^(?:\d*\.?\d+)?$/, 'Total Amount can only contain numeric characters and an optional decimal point')
  .nullable()
  .test('is-empty-or-valid-number', 'Total Amount must be greater than 0', function (value) {
    if (value === '' || value === undefined || value === null) {
      return true;
    }
    const numberValue = value ? parseFloat(value) : 0;
    return !isNaN(numberValue) && numberValue > 0;
  })
        


    });

    console.log("createnewitem 2222");
    console.log(item);
    schemaitem.validate(item, { abortEarly: false })


      .then(() => {



        if (item) {
          const updatedItem = calculateItemTotal(item);

          // Check if item with the same id already exists in setAddItemtoCart
          setAddItemtoCart((prevCart) => {
            // eslint-disable-next-line no-unused-vars
            const existingItemIndex = prevCart.findIndex((cartItem) => cartItem.id === item.id);
            if (existingItemIndex !== -1) {
              // Update existing item
              const updatedCart = [...prevCart];
              updatedCart[existingItemIndex] = updatedItem;
              return updatedCart;
            } else {
              // Add new item
              return [...prevCart, updatedItem];
            }
          });

          setShow(true); // Show the modal or any other UI
          setRefreshRecalculate(true);

          toastrSuccess(staticmessages.ITEMUSERSELECT_ITEMADDEDCART);

        }


        handleClose();
      })
      .catch((validationErrors) => {
        // Yup validation errors
        const errorsObj = {};
        validationErrors.inner.forEach((error) => {
          errorsObj[error.path] = error.message;
        });
        setErrors(errorsObj);
        console.log("createnewitem 5555");
        console.log(errorsObj);
      });


  }




  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // eslint-disable-next-line no-unused-vars

  // Filter items and update their 'isSelected' property
const filteredItems = items.map(item => {
  if (itemtocart.some(cartItem => cartItem.id === item.id)) {
    return { ...item, isSelected: true };
  }
  return item;
}).filter(item =>
  item.item_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  item.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
  item.price.toString().includes(searchTerm)
);


  // const filteredItems = items.filter(item =>
  //   item.item_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //   item.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //   item.price.toString().includes(searchTerm)
  // );


  const onApplyAction = async () => {
    console.log("onApplyAction 222");
    await fetchData();        // Priority 1
    setShowUserProfile(false);
  };


  // eslint-disable-next-line no-unused-vars
  const [showNewCustomerModel, setShowNewCustomerModel] = useState(false);

  const opennewcustomer = () => {
    setShowNewCustomerModel(true);
   // setCreateNewItem({});
  };



  useEffect(() => {
    console.log("AutoSelectInvoice ---" + JSON.stringify(invoice, null, 2));
  }, [invoice]);


  const fetchClientsAndAutoSelectClient = async (client) => {
    try {
      const response = await ClientService.getClients();
      setClients(response.data);


      if (response.data.length > 0) {
        // Find client with id 439
        const findclient = response.data.find((c) => c.id === client.id);
  
        console.log(findclient);
  
  
        if (findclient && !location.state?.selectedinvoice) {

          setSelectedClient({
            value: findclient.id || '',
            label: findclient.client_name || '',
          });

          setSelectedClientDetails(findclient);
  
          setInvoice((prevEstimate) => ({
            ...prevEstimate,
            customer: findclient, client_name: findclient.client_name,
  
          }));
  
          console.log(invoice);
  
          //setInvoice({ ...invoice, customer: findclient , client_name: selectedOption.label });
        }
      }

      
      

    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const onApplyActionCustomer = (client) => {

    console.log('Client data from NewCustomer:', client);
    
    try {
    
       fetchClientsAndAutoSelectClient(client);    // Priority 1
            // Priority 5
     
    } catch (error) {
      console.error('Error fetching data:', error);
    }

    setShowNewCustomerModel(false);
  };

  const onCancelActionCustomer = () => {
    setShowNewCustomerModel(false);
  };

    // eslint-disable-next-line no-unused-vars
  const handleKeyDown = (event) => {
    // Prevent backspace, delete, and other key events
    if (['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
      event.preventDefault();
    }
  };

  const datePickerRef = useRef(null);



         {/* <!-- Page Wrapper --> */}





  const [initialItem, setInitialItem] = useState({});

  // Helper function to remove the 'price' field before comparing objects
const removePriceField = (item) => {
    // eslint-disable-next-line no-unused-vars
    const { price, totalprice, org_price, ...rest } = item; // Exclude 'price', 'totalprice', 'org_price'
  return rest;
};

// Check if any changes were made (ignoring price)
const hasChanges = JSON.stringify(removePriceField(initialItem)) !== JSON.stringify(removePriceField(item));


  const handleCloseUserSelectITem = () => {

    console.error(JSON.stringify(item));
    console.error(JSON.stringify(initialItem));


    if (hasChanges) {
    setShowConfirmModal(true);
    }
    else{
      setShow(false);
    }

    setErrors({});
  };

  const [showConfirmModalInvoice, setShowConfirmModalInvoice] = useState(false);
  const handleConfirmInvoice = () => {
    setShow(false);
    console.log('Confirmed: Modal closed!');
     if (listType === 'invoicelist') {
      history.push('invoice-list');
    }
    else {
      history.push('estimate-list');
    }
    // Perform the action, such as closing the modal or any other task
  };


  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleConfirm = () => {
    setShow(false);
    console.log('Confirmed: Modal closed!');
    // Perform the action, such as closing the modal or any other task
  };

  const handleCancel = () => {
   // console.log('Cancelled');
  };

  const handleCancelAction = () => {

    console.error(JSON.stringify(invoice));
    console.error(JSON.stringify(initialInvoice));

     {/* <!-- Page Wrapper --> */}

         // Check if any changes were made (ignoring price)


         const removeIgnoredFields = (item) => {
          // Exclude 'price', 'totalprice', 'org_price', and any other fields you want to ignore
            // eslint-disable-next-line no-unused-vars
          const { price, totalprice, org_price,subtotal,taxdata,customeritems,defaulttaxdata, ...rest } = item;
        
         
        
          return rest;
        };

         const areItemsEqual = (invoice, initialInvoice) => {
          const cleanInitialItem = removeIgnoredFields(invoice);
          const cleanCurrentItem = removeIgnoredFields(initialInvoice);
        
          return JSON.stringify(cleanInitialItem) === JSON.stringify(cleanCurrentItem);
        };
        
        // Example usage
        const hasChangesInvoice = !areItemsEqual(invoice, initialInvoice);


    if (hasChangesInvoice) {
      setShowConfirmModalInvoice(true);
      }
      else{
        setShow(false);
        if (listType === 'invoicelist') {
          history.push('invoice-list');
        }
        else {
          history.push('estimate-list');
        }
      }
   
  };



  const forceMenuClose = () => {
    setMenu(false);
  };



  const [errorstax, setErrorsTax] = useState({}); // State to hold validation errors

  const [showEditTaxLAbel, setShowEditTaxLAbel] = useState(false);
  const handleCloseUserSelectTaxLAbel = () => {

    setShowEditTaxLAbel(false);
    setErrorsTax({});
  };

  
  const handleEditTaxLabelChange = (id, field, value) => {
    console.log(id);

    if (invoice.id) {
      console.log(id);
invoice.edittaxdata

setInvoice((prevInvoice) => ({
  ...prevInvoice, // Keep the previous invoice data
  edittaxdata: prevInvoice.edittaxdata.map((tax) =>
    tax.id === id ? { ...tax, [field]: value } : tax
  )
}));

      
    }
    else
    {

    
    setEditTaxLabel((prevTaxes) =>
      prevTaxes.map((tax) =>
        tax.id === id ? { ...tax, [field]: value } : tax
      )
    );
    }

    console.log(id);
  };


  const handleCloseActionTaxLAbel = (taxlabel, isedit) => {
    setErrorsTax({});
      // Ensure taxlabel is an array of tax items
      const taxItemSchema = yup.object().shape({
        id: yup.string().required('Tax ID is required'),
        tax_label: yup.string().required('Tax label is required'),
      });

      // Create a schema for the array of tax items
      const schemaTax = yup.object().shape({
        taxes: yup.array().of(taxItemSchema).required('Taxes array is required'), // Ensure the taxes field is an array
      });

      // Wrap taxlabel in an object with taxes property
      const dataToValidate = { taxes: taxlabel };

      console.log("Data to Validate:", dataToValidate);

      schemaTax.validate(dataToValidate, { abortEarly: false })
        .then(() => {
    

          if (isedit) {
            handleCloseUserSelectTaxLAbel();
            console.log("createnewitem 666 000");
          } else {

    InvoiceService.updateTaxLabel(dataToValidate)
    .then((response) => {
      setErrors({});
      console.log("createnewitem 666 000");
      console.log(response.data[0]);
      if (response.data[0].id) {
       
     

        toastrSuccess(staticmessages.TAXL_USUCCESS);
        getDefaultTax();
        console.log("createnewitem 666 222----");
       

        handleCloseUserSelectTaxLAbel();


      }
      if (response.errorMessage) {
        toastrError(response.errorMessage);
        setErrorItem(response.errorMessage);
        setErrors({ ...errors, ['item_name']: response.errorMessage });

        console.log(response.errorMessage);

      }

      console.error(response);

    })
    .catch((error) => {
      console.log(error.message);
      setErrors({ ...errors, 'item_name': error.message });
      console.error('Error fetching units:', error);
    });

  }

  })
  .catch((validationErrors) => {


    const errorsObj = {};
    validationErrors.inner.forEach((error) => {
      errorsObj[`tax_label_${error.path}`] = error.message; // Assuming error.path gives you the right identifier
    });
    setErrorsTax(errorsObj);
    console.log("Validation Errors:", validationErrors);

  });


  }
 // eslint-disable-next-line no-unused-vars
  const [isClearable, setIsClearable] = useState(true);
     

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} forceMenuClose={forceMenuClose} />
        <Sidebar />
        {/* <!-- Page Wrapper --> */}
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="card mb-0">
              <div className="card-body">
                <div className="page-header">
                  <div className="content-page-header">
                  
                    <h5>{invoice.id ? "Edit" : "Add"} {customtitle}</h5>









                  </div>
                </div>
                <div className="row">
              
                 
                  <form >
                    <div className="col-md-12">
                      <div className="form-group-item border-0 mb-0">
                        <div className="row align-item-center">
                          <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className="input-block mb-3">
                              <label>{customtitle} Number<span className="alertstrik">*</span></label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={`Enter ${customtitle} Number`}
                                value={invoice.invoice_number}
                                onChange={handleChangeInvoice}
                                id="invoice_number"
                                name="invoice_number"
                              />
                              <small className="error">{errors?.invoice_number}</small>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="input-block mb-3">
                              <label>{staticmessages.CUSTOMER_LABEL_CLIENT}<span className="alertstrik">*</span></label>
                              <ul className="form-group-plus css-equal-heights">
                                <li>
                                  <Select
                                    value={selectedClient}
                                    onChange={handleCustomerChange}
                                    options={formattedOptionsClients(clients)}
                                    placeholder="Select Client"
                                  />
                                </li>
                                <li>
                                  {/* <Link
                                    className="btn btn-primary form-plus-btn"
                                    to="/add-customer"
                                  >
                                    <FeatherIcon icon="plus-circle" />
                                  </Link> */}

<button  type="button"
                                        className="btn btn-primary"
                                        onClick={opennewcustomer}



                                      >
                                        <i className="fe fe-plus-circle" />
                                      </button>


                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className="input-block mb-3">
                              <label>{customtitle} Date<span className="alertstrik">*</span></label>
                              <div className="cal-icon cal-icon-info">
                                <DatePicker
                                  className="datetimepicker form-control"
                                  selected={startInvoiceDate}
                                  onChange={(date) => HandaleInvoiceDate(date)}
                                  maxDate={new Date()}
                                  dateFormat="dd/MM/yyyy"
                                  onKeyDown={(e) => e.preventDefault()} // Prevent manual input
                                ></DatePicker>
                                {/* <FeatherIcon icon="calendar"/> */}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className="input-block mb-3">
                              <label>Order No.</label>

                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Order Number"
                                value={invoice.order_no}
                                onChange={handleChangeInvoice}
                                id="order_no"
                                name="order_no"
                                onClick={(e) => e.target.select()}
                              />
  <small className="error">{errors?.order_no}</small>
                            </div>
                          </div>



                          <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className="input-block mb-3">
                              <label>Order Date</label>
                              <div className="cal-icon cal-icon-info"  onClick={() => datePickerRef.current.setFocus()}>
                                
                                <DatePicker
                                  ref={datePickerRef}
                                  className="datetimepicker form-control"
                                  selected={startOrderDate}
                                  onChange={(order_date) => HandaleOrderDate(order_date)}
                                  maxDate={new Date()}
                                  dateFormat="dd/MM/yyyy"
                                  onKeyDown={(e) => e.preventDefault()} // Prevent manual input
                                  ></DatePicker>
                                  
                                  <small className="error">{errors?.order_date}</small>
                              
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-2 col-md-6 col-sm-12">


                            <div className="input-block mb-3">
                              <label>Payment Term</label>
                                   
                              <select
                                  id="payment_terms"
                                name="payment_terms"
                                className="form-select"
                                value={selectedTerm}
                                onChange={(e) => handleTermChange(e)}
                              >
                                <option value="">Select Payment Term</option>

                                {PaymentTerms.map((term) => (
                                  <option key={term.value} value={term.value}>
                                    {term.label}
                                  </option>
                                ))}


                              </select>
                              <small className="error">{errors?.payment_terms}</small>
                            </div>
                          </div>


                          <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className="input-block mb-3">
                              <label>Due Date</label>
                              <div className="cal-icon cal-icon-info">
                                <DatePicker
                                  className="datetimepicker form-control"
                                  selected={startDate}
                                  onChange={(date) => HandaleDueDate(date)}
                                  minDate={new Date()}
                                  dateFormat="dd/MM/yyyy"
                                  onKeyDown={(e) => e.preventDefault()} // Prevent manual input
                                ></DatePicker>
                              </div>
                            </div>
                          </div>
                          <>


                            <div className="col-lg-7">
                              <div className="d-flex justify-content-between flex-wrap">
                                <div className="input-block mb-3 recurring-tab " id="newclass">
                                  <label>Recurring </label>
                                  <ul
                                    className="nav nav-pills d-flex"
                                    id="pills-tab"
                                    role="tablist"
                                  >
                                    <li className="nav-item" role="presentation">
                                      <button
                                        className={`nav-link ${isRecurring ? "active yes" : ""}`}
                                        id="pills-home-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-home"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-home"
                                        aria-selected="true"
                                        onClick={() => handleRecurringChange("yes")}
                                      >
                                        Yes
                                      </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                      <button
                                        className={`nav-link ${!isRecurring ? "no active" : ""}`}
                                        id="pills-profile-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-profile"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-profile"
                                        aria-selected="false"
                                        onClick={() => handleRecurringChange("no")}
                                      >
                                        No
                                      </button>
                                    </li>
                                  </ul>
                                </div>




                                {isRecurring && (
                                  <>
                                    <div className="input-block mb-3">
                                      <label>Subscription Term</label>

                                      <select
                                        className="form-select"
                                        value={selectedSubscriptionTerm}
                                        onChange={(e) => handleSubscriptionTermChange(e)}
                                      >
                                        <option value="">Select Subscription</option>

                                        {SubscriptionTerms.map((term) => (
                                          <option key={term.value} value={term.value}>
                                            {term.label}
                                          </option>
                                        ))}

                                      </select>
                                      <small className="error">{errors?.subscription_terms}</small>
                                    </div>


                                    <div className="input-block mb-3">
                                      <label>Subscription / Recurring</label>
                                      <div className="cal-icon cal-icon-info">
                                        <DatePicker
                                          className="datetimepicker form-control"
                                          selected={subscriptionstartDate}
                                          onChange={(date) => HandaleSubscriptionDate(date)}
                                          minDate={new Date()}
                                          dateFormat="dd/MM/yyyy"
                                        ></DatePicker>
                                      </div>
                                    </div>
                                  </>
                                )}




                              </div>


                            </div>
                            <div className="col-lg-8">
                              <div className="input-block mb-3">

                                <ul className="form-group-plus css-equal-heights">



                                  <li>
                                    <div role="group" aria-label="Recurring Options">
                                      <button
                                        type="button"
                                        className="btn btn-primary  me-2"
                                        onClick={openitemlist}



                                      >
                                        Add Item
                                      </button>

                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={opennewitem}



                                      >
                                        <i className="fe fe-plus-circle" />
                                      </button>

                                    </div>


                                  </li>

                                  <li></li>
                                  <li></li>
                                  <li></li>
                                  <li></li>
                                  <li></li>
                                  <li></li>
                                  <li></li>
                                  <li></li>
                                </ul>
                              </div>
                            </div>
                          </>
                        </div>
                      </div>
                      <>
                        <div className="form-group-item">
                          <div className="card-table">
                            <div className="card-body">
                              <div className="table-responsive no-pagination">
                                <table className="table table-center table-hover datatable">
                                  <thead className="thead-light">
                                    <tr>
                                      <th>Item</th>
                                      <th>Quantity</th>
                                      <th>Unit</th>
                                      <th>Rate</th>
                                      <th>Discount</th>

                                      {(showTaxDisplay) && (
                                        <th>Tax %</th>
                                      )}


                                      <th>Amount</th>
                                      <th className="no-sort">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>

                                    {itemtocart.map(item => (
                                      <tr key={item.id}>
                                        <td>{item.item_name}</td>
                                        <td>
                                          {item.quantity}
                                        </td>
                                        <td>{item.unit}</td>
                                        <td>
                                          {item.org_price}
                                        </td>
                                        <td>


                                          {item.discountValue ? item.discountValue : 0}{item.itemdiscounttype ? "%" : ""}</td>
                                        {(showTaxDisplay) && (
                                          <td> {item.taxrate}</td>
                                        )}


                                        <td> {item.totalprice}</td>
                                        <td className="d-flex align-items-center hachheightborder">

                                          <button
                                            type="button"
                                            className="btn-action-icon me-2 roundcorner"
                                            onClick={() =>
                                              handleEditConfirm(item)
                                            }
                                          >
                                            <span><i className="fe fe-edit" />
                                            </span>
                                          </button>
                                          <button
                                            type="button"
                                            className="btn-action-icon roundcorner"

                                            onClick={() =>
                                              handleDeleteConfirm(item)

                                            }
                                          >
                                            <span>
                                              <i className="fe fe-trash-2" />
                                            </span>
                                          </button>

                                        </td>
                                      </tr>
                                    ))}

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="row">
                       
                        {(!showTaxDisplay) && (
                           <>
                          <div className="col-md-3">
                            <div className="input-block mb-3">
                              <label>Discount Type</label>
                              <Select

                                value={selectedPercentage}
                                options={formattedOptions(percentage)}
                                placeholder="Discount Type"
                                id="discounttype"
                                name="discounttype"
                                onChange={(selectedOption) => handlePercentageChange('discounttype', selectedOption)}

                              />
                            </div>
                          </div>
                          {(invoice.discounttype !== 'nodiscount' && invoice.discounttype != '') && (

<div className="col-md-2">


  <div className="input-block mb-3">
    <label>Discount {invoice.discounttype == "Percentage" ? "(%)" : ""} </label>
    <input
      onChange={handleChangeInvoiceDiscount}
      value={invoice.discount}
      type="number"
      className="form-control"
      placeholder={"Discount"}
      id="discount"
      name="discount"
      onClick={(e) => e.target.select()}
    />
  </div>

</div>

)}


                          </>
                        )}
                        

                       
                         
                          

 

                          <div className="col-md-3">
                            <div className="input-block mb-3">
                              <label>Tax Type</label>
                              <Select

                                value={selectedTax}
                                options={formattedOptions(selectedtax)}
                                placeholder="Tax Type"
                                id="selectedtax"
                                name="selectedtax"
                                onChange={(selectedOption) => handleTaxChange('selectedtax', selectedOption)}

                              />
                            </div>
                          </div>
                          {(invoice.selectedtax === 'ontotal') && (
                            <div className="col-md-2">

                              <div className="input-block mb-3">
                                <label>Tax %</label>

                                <input
                                  onChange={handleChangeInvoiceTaxRate}
                                  value={invoice.invoice_taxrate}
                                  type="number"
                                  className="form-control"
                                  placeholder={"Tax Rate"}
                                  id="invoice_taxrate"
                                  name="invoice_taxrate"
                                  onClick={(e) => e.target.select()}
                                />
                              </div>
                            </div>
                          )}

                        </div>


                        <div className="row">



                          <div className="col-md-4">
                            <div className="input-block mb-3">


                            </div>
                          </div>
                          <div className="col-md-4" />
                        </div>
                      </>
                      <div className="form-group-item border-0 p-0">
                        <div className="row">
                          <div className="col-xl-6 col-lg-12">
                            <small className="error">{errors?.discount}</small>
                          </div>
                          <div className="col-xl-6 col-lg-12">
                            <div className="form-group-bank">
                              <div className="invoice-total-box">
                                <div className="invoice-total-inner">
                                  <p>
                                    Subtotal Amount <span>{currformat} {invoice.subtotal}</span>
                                  </p>

                                  {(invoice.discount_amount > 0) && (
                                    <p>
                                      Discount {invoice.discounttype === "Percentage" ? `(${invoice.discount}%)` : ""}
                                      <span>{currformat} {invoice.discount_amount}</span>
                                    </p>
                                  )}


{(invoice.selectedtax !== "none") && (
                                  <p>
                                    Taxable Amount <span>{currformat} {invoice.taxable_amount}</span>
                                  </p>
                                  )}
                                  
                                  
                                  <p onClick={() => setShowEditTaxLAbel(true)}>
                                   Tax ({returnDataValue(selectedtax, invoice.selectedtax, "label")})  

                                    {invoice.selectedtax !== "none" && (
  displaytax.map((item, index) => (
    <p key={index}>
      <i className="fe fe-edit" /> {item.labelDiplay} <span>{currformat} {item.taxAmount}</span>
    </p>
  ))
)}

                                  </p>

                                </div>
                                <div className="invoice-total-footer">
                                  <h4>
                                    Total Amount <span>{currformat} {invoice.gtotal}</span>
                                  </h4>
                                </div>
                              </div>


                            </div>
                          </div>
                        </div>
                      </div>

                      <button
                        type="button"
                        className="btn btn-primary  me-2"
                        onClick={handleCancelAction}
                      >
                        Cancel
                      </button>

                      <button type="button" className="btn btn-primary" onClick={handleSaveAction}>
                        {invoice.id ? 'Save' : 'Create'} {customtitle}
                      </button>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>






        <Modal show={showEditTaxLAbel} onHide={handleCloseUserSelectTaxLAbel}>
  <Modal.Header>
    <Modal.Title>Edit Tax Label</Modal.Title>

    <div className="d-flex ms-auto align-items-center">
      <Button type="button" variant="primary" onClick={() => handleCloseActionTaxLAbel(taxlabel, invoice.id ? true : false)}>
        Save Changes
      </Button>

      

      <button
        type="button"
        className="btn-close ms-2"
        onClick={handleCloseUserSelectTaxLAbel}
        aria-label="Close"
      />
    </div>
  </Modal.Header>
            <Modal.Body  >

              <div className="row" >

      


           {/* Dynamic tax fields */}
           {(invoice.id ? displaytax : taxlabel).map((tax, index) => (
  <div key={`${tax.id}-${index}`} className="col-lg-12 col-md-12 col-sm-12">
          <div className="input-block mb-3 add-products">
            <label>Tax Label</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Label"
              onChange={(e) =>
                handleEditTaxLabelChange(tax.id, invoice.id ? 'taxLabel' : 'tax_label', e.target.value)
              }
              value={invoice.id ? tax.taxLabel : tax.tax_label}
            />
          
            <small className="error">  {errorstax?.[`tax_label_taxes[${index}].tax_label`]}</small>
          
          </div>
        </div>
      ))}

              
               


              </div>
             
            </Modal.Body>
          
          </Modal>




        


        {/* Delete Items Modal */}
        <Modal show={showDel} >

          <Modal.Body  >


            <div className="modal-header border-0 justify-content-center pb-0">
              <div className="form-header modal-header-title text-center mb-0">
                <h4 className="mb-2">Delete Product / Services</h4>
                <p>Are you sure you want to delete?</p>
              </div>
            </div>
            <div className="modal-body">
  <div className="modal-btn delete-action">
    <div className="row justify-content-center">
      <div className="col-auto">
        <Link
          to="#"
          className="btn btn-primary paid-continue-btn"
          onClick={() => DeleteItem()}
        >
          Delete
        </Link>
      </div>
      <div className="col-auto">
        <Link
          to="#"
          data-bs-dismiss="modal"
          className="btn btn-primary"
          onClick={() => setShowDel(false)}
        >
          Cancel
        </Link>
      </div>
    </div>
  </div>
</div>




          </Modal.Body>

        </Modal>


        <>





 {/* Confirmation Modal */}
 <ConfirmationModal
        show={showConfirmModal}
        setShow={setShowConfirmModal}
        message={staticmessages.ITEMUSERSELECT_DATALOST}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />


       {/* Confirmation Modal 2 */}
       <ConfirmationModal
  show={showConfirmModalInvoice}
  setShow={setShowConfirmModalInvoice}
  message={`Are you sure to cancel the ${listType === "invoicelist" ? "Invoice" : "Estimate"}? Your entries will be lost.`}
  onConfirm={handleConfirmInvoice}
  onCancel={handleCancel}
/>



        <Modal show={show} onHide={handleCloseUserSelectITem}>
  <Modal.Header>
    <Modal.Title>Item</Modal.Title>

    <div className="d-flex ms-auto align-items-center">
      <Button type="button" variant="primary" onClick={() => handleCloseAction(item)}>
        Save Changes
      </Button>

      

      <button
        type="button"
        className="btn-close ms-2"
        onClick={handleCloseUserSelectITem}
        aria-label="Close"
      />
    </div>
  </Modal.Header>
            <Modal.Body  >

              <div className="row" tabIndex={-1}>



              <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3 add-products">
                    <label>
                      Quantity
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Quantity"
                      onChange={handleChange}
                      id="quantity"
                      name="quantity"
                      value={item.quantity}
                      onClick={(e) => e.target.select()}
                    />
                    <small className="error">{errors?.quantity}</small>
                    {/* Optionally display validation errors for hsn_sac */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3 add-products">
                    <label>
                    Quantity X Prices 
                    </label>
                    <input
                      type="number"
                      className="form-control"

                      readOnly
                      id="totalprice"
                      name="totalprice"
                      value={item.totalprice}
                    />
                    {/* Optionally display validation errors for hsn_sac */}
                    <small className="error">{errors?.totalprice}</small>
                  </div>
                </div>
                

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>
                      {staticmessages.ITEMS_LABEL_SINGLEITEM} Name
                      <span className="alertstrik">*</span> (Description)
                    </label>
                    <input
                      onChange={handleChange}
                      id="item_name"
                      name="item_name"
                      value={item.item_name}
                      type="text"
                      className="form-control"
                      placeholder={`Enter ${staticmessages.ITEMS_LABEL_SINGLEITEM} Name`}
                    />
                    <small className="error">{errors?.item_name}</small>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>
                      Price<span className="alertstrik">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Selling Price"
                      onChange={handleChangeEdit}
                      id="price"
                      name="price"
                      value={item.org_price}
                      onClick={(e) => e.target.select()}
                    />
                    <small className="error">{errors?.price}</small>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>
                      Tax Rate %{' '}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Tax Rate"
                      onChange={handleChange}
                      id="taxrate"
                      name="taxrate"
                      value={item.taxrate}
                      onClick={(e) => e.target.select()}
                    />
                    {/* Optionally display validation errors for taxrate */}
                    <small className="error">{errors?.taxrate}</small>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3 add-products">
                    <label>
                      HSN/SAC
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter HSN/SAC"
                      onChange={handleChange}
                      id="hsn_sac"
                      name="hsn_sac"
                      value={item.hsn_sac}
                    />
                    {/* Optionally display validation errors for hsn_sac */}
                  </div>
                </div>




                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>
                      Discount Type{' '}
                    </label>

                    <Select

                      value={selectedItemDiscount}
                      options={formattedOptions(percentage)}
                      placeholder="Select Discount Type"
                      id="itemdiscounttype"
                      name="itemdiscounttype"
                      onChange={(selectedOption) => handleChangeDiscountItem('itemdiscounttype', selectedOption)}

                    />
<small className="error">{errors?.itemdiscounttype}</small>

                    {/* Optionally display validation errors for taxrate */}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3 add-products">
                    <label>
                      Discount
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Discount"
                      onChange={handleChangeDecimalVal}
                      id="discountValue"
                      name="discountValue"
                      value={item.discountValue}
                      onClick={(e) => e.target.select()}
                    />
                    <small className="error">{errors?.discountValue}</small>
                    {/* Optionally display validation errors for hsn_sac */}
                  </div>
                </div>


              


                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>Category<span className="alertstrik">*</span></label>
                   
                    {item.category && categories && (
                      <Select
                        defaultValue={item.category ? { value: item.category, label: item.category } : item.category}
                        id="category"
                        name="category"
                        onChange={(selectedOption) => handleChangeCustom(selectedOption, 'category')}
                        options={categories}
                        placeholder="Select Category"
                      />
                    )}
                    <small className="error">{errors?.category}</small>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>Units</label>

                    <Select
                      id="unit"
                      name="unit"
                      defaultValue={item.unit ? { value: item.unit, label: item.unit } : item.unit}
                      onChange={(selectedOption) => handleChangeCustomUnit(selectedOption, 'unit')}
                      options={units}
                      placeholder="Select Unit"
                      isClearable
                    />

                    <small className="error">{errors?.unit}</small>
                  </div>
                </div>



              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 text-danger">
                {previousdiscount}
              </div>
            </Modal.Body>
          
          </Modal>






          <Modal show={showSelect} onHide={handleCloseSelectCloseList} scrollable={true} >
            <Modal.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Modal.Title>Items</Modal.Title>
              <div style={{ flex: 1, marginLeft: '10px' }}>
                <input
                  type="text"
                  placeholder="Search items..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  style={{ width: '100%', padding: '5px' }}
                />
              </div>
            </Modal.Header>

            <Modal.Body>

              <table className="table">
                <thead>
                  <tr>
                    <th >#</th>
                    <th >Item</th>
                    <th >Category</th>
                    <th >Price</th>
                  </tr>
                </thead>


                <tbody>
                  {filteredItems.map(item => (
                    <tr key={item.id} onClick={() => handleCheckboxChangePopup(item.id)}>
                      <th onClick={() => handleCheckboxChangePopup(item.id)}> <div className="checkbox"



                      ><label>
                        
                        <input 
              type="checkbox" 
              name="checkbox" 
              checked={item.isSelected} // Use the `item.isSelected` value here
              onChange={() => handleCheckboxChangePopup(item.id)} // Optional: if you want to handle changes to the checkbox
            />
                     
                      
                      </label>

                      </div></th>
                      <td onClick={() => handleCheckboxChangePopup(item.id)}>{item.item_name}</td>
                      <td onClick={() => handleCheckboxChangePopup(item.id)}>{item.category}</td>
                      <td onClick={() => handleCheckboxChangePopup(item.id)}>{item.price}</td>
                    </tr>
                  ))}

                </tbody>
              </table>


            </Modal.Body>
            <Modal.Footer>
              <Button className="me-2" variant="primary" onClick={handleCloseSelectClose}>
                Close
              </Button>
              <Button variant="primary" onClick={() => handleCloseSelect(item)}>
                Select Items
              </Button>
            </Modal.Footer>
          </Modal>







          <Modal show={showNewItem} onHide={handleCloseSelectItem}>
            <Modal.Header >
              <Modal.Title>Add New Item</Modal.Title>
            </Modal.Header>
            <Modal.Body  >

              <div className="row" tabIndex={-1}>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>Category<span className="alertstrik">*</span></label>
                   
                    <Select
                      defaultValue={createnewitem.category ? { value: createnewitem.category, label: createnewitem.category } : createnewitem.category}
                      id="category"
                      name="category"
                      onChange={(selectedOption) => handleChangeCustomNewItem(selectedOption, 'category')}
                      options={categories}
                      placeholder="Select Category"
                    />

                    <small className="error">{errors?.category}</small>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>Units</label>

                    <Select
                      id="unit"
                      name="unit"
                      defaultValue={createnewitem.unit ? { value: createnewitem.unit, label: createnewitem.unit } : createnewitem.unit}
                      onChange={(selectedOption) => handleChangeCustomNewItem(selectedOption, 'unit')}
                      options={units}
                      placeholder="Select Unit"
                      isClearable
                    />
                    

                    <small className="error">{errors?.unit}</small>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>
                      {staticmessages.ITEMS_LABEL_SINGLEITEM} Name
                      <span className="alertstrik">*</span> (Description)
                    </label>
                    <input
                      onChange={handleChangeNewItem}
                      id="item_name"
                      name="item_name"
                      value={createnewitem.item_name}
                      type="text"
                      className="form-control"
                      placeholder={`Enter ${staticmessages.ITEMS_LABEL_SINGLEITEM} Name`}
                    />
                    <small className="error">{errors?.item_name}</small>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>
                    Price<span className="alertstrik">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Selling Price"
                      onChange={handleChangeNewItem}
                      id="price"
                      name="price"
                      value={createnewitem.price}
                      onClick={(e) => e.target.select()}
                    />
                    <small className="error">{errors?.price}</small>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>
                      Tax Rate %{' '}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Tax Rate"
                      onChange={handleChangeNewItem}
                      id="taxrate"
                      name="taxrate"
                      value={createnewitem.taxrate ? createnewitem.taxrate : 0}
                      onClick={(e) => e.target.select()}

                    />
                    <small className="error">{errors?.taxrate}</small>
                    {/* Optionally display validation errors for taxrate */}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3 add-products">
                    <label>
                      HSN/SAC
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter HSN/SAC"
                      onChange={handleChangeNewItem}
                      id="hsn_sac"
                      name="hsn_sac"
                      value={createnewitem.hsn_sac}
                    />
                    {/* Optionally display validation errors for hsn_sac */}
                  </div>
                </div>



                    
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>
                      Discount Type{' '}
                    </label>

                    <Select

                      value={selectedItemDiscount}
                      options={formattedOptions(percentage)}
                      placeholder="Select Discount Type"
                      id="itemdiscounttype"
                      name="itemdiscounttype"
                      onChange={(selectedOption) => handleChangeDiscountNewItem('itemdiscounttype', selectedOption)}

                    />
 <small className="error">{errors?.itemdiscounttype}</small>

                    {/* Optionally display validation errors for taxrate */}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3 add-products">
                    <label>
                      Discount
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Discount"
                      onChange={handleChangeNewItem}
                      id="discountValue"
                      name="discountValue"
                      value={createnewitem.discountValue}
                      onClick={(e) => e.target.select()}
                    />
                     <small className="error">{errors?.discountValue}</small>
                    {/* Optionally display validation errors for hsn_sac */}
                  </div>
                </div>


                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3 add-products">
                    <label>
                      Quantity
                    </label>
                    <input
                      type="number  "
                      className="form-control"
                      placeholder="Enter Quantity"
                      onChange={handleChangeNewItem}
                      id="quantity"
                      name="quantity"
                      value={createnewitem.quantity}
                      onClick={(e) => e.target.select()}
                    />
                    <small className="error">{errors?.quantity}</small>
                    {/* Optionally display validation errors for hsn_sac */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="input-block mb-3 add-products">
                    <label>
                      Total
                    </label>
                    <input
                      type="text"
                      className="form-control"

                      readOnly
                      id="totalprice"
                      name="totalprice"
                      value={createnewitem.totalprice ? createnewitem.totalprice : 0}
                    />
                    {/* Optionally display validation errors for hsn_sac */}
                  </div>
                </div>



              </div>

              {
                erroritem
              }

            </Modal.Body>
            <Modal.Footer>
              <Button className="me-2" variant="primary" onClick={handleCloseSelectItem}>
                Close
              </Button>
              <Button variant="primary" onClick={() => createNewItemAction(createnewitem)}>
                Create
              </Button>
            </Modal.Footer>
          </Modal>



          {/* Check User Profile Setting Modal */}
          <Modal show={showUserProfile} onHide={() => setShowUserProfile(false)}>
            <Modal.Body>
              <ProfileSetting onApplyAction={onApplyAction} />
            </Modal.Body>
          </Modal>


  {/* Add New Customer Setting Modal */}
   <Modal show={showNewCustomerModel} onHide={() => setShowNewCustomerModel(false)}>
            <Modal.Body>
              <NewCustomer onApplyActionCustomer={onApplyActionCustomer} onCancelActionCustomer={onCancelActionCustomer}  />
            </Modal.Body>
          </Modal>



        </>
      </div>
    </>
  );
};
export default AddInvoice;
